import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    IconButton,
    Collapse,
    Box,
    CircularProgress,
    TextField,
    MenuItem,
    Select,
    FormControl,
    TablePagination,
    LinearProgress,
    Card,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandedContent from '../../components/monitor-expanded-content';
import { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import MonitorRow from '../monitor-row';
import { getAllTakeDowns } from '../../api';
import { enqueueSnackbar } from 'notistack';

const TAKEDOWN_STATUSES = ['All', 'Not Initiated', 'Draft', 'In Progress', 'Completed'];
const DATE_SORT_OPTIONS = ['All', 'Recent to Oldest', 'Oldest to Recent'];
const STATUS_FILTER_OPTIONS = ['All', 'Enabled', 'Disabled'];
const ALERTS_SORT_OPTIONS = ['All', 'High to Low', 'Low to High'];

function MonitorsTable({ monitors, loading, expandedRow, onRowClick, onMonitorDelete, preselectedDomain }) {

    const [filters, setFilters] = useState({
        domain: preselectedDomain ? preselectedDomain : '',
        dateSort: 'All',
        status: 'All',
        alertsSort: 'All',
        takedownStatus: 'All'
    });

    const [takeDowns, setTakeDowns] = useState([]);

    const handleFilterChange = (field, value) => {
        setFilters(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const sortMonitors = (monitors) => {
        let sorted = [...monitors];

        // Date sorting
        if (filters.dateSort !== 'All') {
            sorted.sort((a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return filters.dateSort === 'Recent to Oldest'
                    ? dateB - dateA
                    : dateA - dateB;
            });
        }

        // Alerts sorting
        if (filters.alertsSort !== 'All') {
            sorted.sort((a, b) => {
                return filters.alertsSort === 'High to Low'
                    ? b.grouped_alerts?.total_count - a.grouped_alerts?.total_count
                    : a.grouped_alerts?.total_count - b.grouped_alerts?.total_count;
            });
        }

        return sorted;
    };

    const filteredMonitors = sortMonitors(monitors).filter(monitor => {
        return (
            monitor.domain.toLowerCase().includes(filters.domain.toLowerCase()) &&
            (filters.status === 'All' ||
                (filters.status === 'Enabled' && monitor.enabled) ||
                (filters.status === 'Disabled' && !monitor.enabled)) &&
            (filters.takedownStatus === 'All' || monitor.takedownStatus === filters.takedownStatus)
        );
    });

    useEffect(() => {
        getAllTakeDowns()
            .then((response) => {
                setTakeDowns(response?.data || []);
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar("Unable to load takedowns", {
                    variant: "error",
                });
            });
    }, []);

    return (
        <TableContainer sx={{ maxHeight: 'calc(100vh - 100px)', boxShadow: 'none' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Domain Name</TableCell>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Total Alerts</TableCell>
                        <TableCell>Takedown Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField
                                size="small"
                                placeholder="Filter domain"
                                value={filters.domain}
                                onChange={(e) => handleFilterChange('domain', e.target.value)}
                                fullWidth
                            />
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={filters.dateSort}
                                    onChange={(e) => handleFilterChange('dateSort', e.target.value)}
                                >
                                    {DATE_SORT_OPTIONS.map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={filters.status}
                                    onChange={(e) => handleFilterChange('status', e.target.value)}
                                >
                                    {STATUS_FILTER_OPTIONS.map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={filters.alertsSort}
                                    onChange={(e) => handleFilterChange('alertsSort', e.target.value)}
                                >
                                    {ALERTS_SORT_OPTIONS.map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            {/* <FormControl fullWidth size="small">
                            <Select
                                value={filters.takedownStatus}
                                onChange={(e) => handleFilterChange('takedownStatus', e.target.value)}
                            >
                                {TAKEDOWN_STATUSES.map(status => (
                                    <MenuItem key={status} value={status}>{status}</MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>

                    {takeDowns && filteredMonitors?.map((monitor) => {
                        const takedown = takeDowns?.filter(x => x.malicious_domain === monitor.domain)[0];
                        return (
                            <>
                                <MonitorRow monitor={monitor} takedown={takedown ?? null} onRowClick={onRowClick} onMonitorDelete={onMonitorDelete} />
                                <TableRow>
                                    <TableCell
                                        style={{
                                            padding: 0,  // Remove cell padding
                                            backgroundColor: '#f5f5f5' // Match background color
                                        }}
                                        colSpan={6}
                                    >
                                        <Collapse
                                            in={expandedRow === monitor.uid}
                                            timeout="auto"
                                            unmountOnExit
                                            sx={{
                                                backgroundColor: '#f5f5f5', // Match background during transition
                                            }}
                                        >
                                            <ExpandedContent monitor={monitor} takedown={takedown} />
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default MonitorsTable; 