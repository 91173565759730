import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    CircularProgress,
    Alert,
    Container,
    Box,
    CardHeader,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { deleteTakeDown, getAllTakeDowns, sendTakeDown } from '../../api';
import { enqueueSnackbar } from 'notistack';
import TakedownRow from '../../components/takedown-row';
import DashboardLayout from '../../components/Dashboard/layout';

const Takedown = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [loadTable, setLoadTable] = useState(true);

    const [isSendingTakedown, setIsSendingTakeDown] = useState(false);
    const [additionalNotes, setAdditionalNotes] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expandedRow, setExpandedRow] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(null);


    const handleDeleteModal = (takedown) => {
        setOpenDialog(true);
        setPendingDelete(takedown.uid)
    }

    const handleDeleteModalClose = () => {
        setOpenDialog(false);
        setPendingDelete(null)
    }

    const handleTakeDownDelete = () => {

        deleteTakeDown(pendingDelete).then((response) => {
            setOpenDialog(false);

            setData((prev) => prev.filter((t) => t.uid !== pendingDelete.uid));

            enqueueSnackbar("Takedown deleted successfully", {
                variant: "success",
            });
            setLoadTable(true)
        }).catch(() => {
            enqueueSnackbar("Unable to delete takedown", {
                variant: "error",
            });
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSendTakedown = (uid) => {

        setIsSendingTakeDown(true)
        const data = {
            additional_notes: additionalNotes
        }

        sendTakeDown(uid, data).then((response) => {
            enqueueSnackbar("Takedown sent successfully", {
                variant: "success",
            });
            setIsSendingTakeDown(false)

        }).catch((error) => {
            if (
                error.response.status === 400
            ) {

                if (Object.keys(error.response.data).includes("signed_power")) {
                    enqueueSnackbar(error.response.data.signed_power, {
                        variant: "error",
                    });
                } else {
                    enqueueSnackbar(error.response.data.details, {
                        variant: "warning",
                    });
                }

            } else {
                enqueueSnackbar("Takedown was unable to send. An error occured.", {
                    variant: "error",
                });
            }
            setIsSendingTakeDown(false)
        })
    }

    const handleAdditonalInfoChange = (val) => {
        console.log(val)
        setAdditionalNotes(setAdditionalNotes)
    }

    useEffect(() => {
        getAllTakeDowns()
            .then((response) => {
                setData(response?.data || []);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar("Unable to load takedowns", {
                    variant: "error",
                });
                setLoading(false);
            });
    }, []);

    return (
        <DashboardLayout>
            <Container maxWidth="100%" sx={{ p: 3 }}>

                <Box>
                    <Paper elevation={3} sx={{ p: 0, mb: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                            <CardHeader title="Takedown Management" sx={{ p: 0 }} />
                        </Box>
                        <Box>
                            {loading &&
                                <LinearProgress />
                            }
                        </Box>

                        <>
                            <Alert
                                severity="info"
                                icon={<InfoIcon />}
                                sx={{
                                    mb: 2,
                                    '& .MuiAlert-message': {
                                        width: '100%'
                                    }
                                }}
                            >
                                We automatically submit your takedown request to registrars, hosting providers, and blacklists.
                                Our system runs daily scans to track remediation progress. Once a threat is fully resolved,
                                statuses update accordingly, and the takedown request is marked as complete.
                            </Alert>

                            <Paper sx={{ borderRadius: 0, boxShadow: 'none' }}>
                                <TableContainer>
                                    <Table
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                borderBottom: 'none',
                                                padding: '8px 16px',
                                                height: '40px',
                                                fontSize: '0.875rem'
                                            },
                                            '& .MuiTableHead-root': {
                                                backgroundColor: '#fff',
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)'
                                            }
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox" />
                                                <TableCell>Malicious Domain</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell>Takedown Status</TableCell>
                                                <TableCell>Blacklists Status</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => (
                                                    <TakedownRow
                                                        key={row.uid}
                                                        row={row}
                                                        expanded={expandedRow === row.uid}
                                                        onExpand={() => setExpandedRow(expandedRow === row.uid ? null : row.uid)}
                                                        onSendTakeDown={handleSendTakedown}
                                                        onAdditionalInfoChange={handleAdditonalInfoChange}
                                                        onTakeDownDelete={handleDeleteModal}
                                                    />
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </>
                    </Paper>
                </Box>


                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Delete Takedown</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete takedown? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDeleteModalClose()}>Cancel</Button>
                        <Button onClick={() => handleTakeDownDelete()} color="error">
                            Delete Takedown
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </DashboardLayout>
    );
};

export default Takedown;