import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import PhishingIcon from "@mui/icons-material/Phishing";
import LinkIcon from "@mui/icons-material/Link";
import PolicyIcon from "@mui/icons-material/Policy";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import HttpsIcon from "@mui/icons-material/Https";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import "./widgets.css";

const riskAssessmentData = [
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="M592-302 450-444v-196h60v171l124 124-42 43ZM450-730v-90h60v90h-60Zm280 280v-60h90v60h-90ZM450-140v-90h60v90h-60ZM140-450v-60h90v60h-90ZM480.27-80q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
			</svg>
		),
		title: "Domain Age Analysis",
		description: "Domain age and trustworthiness",
	},
	{
		icon: (
			<svg
				height="100%"
				width="100%"
				fill="#2fafed"
				class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dskzcr-MuiSvgIcon-root svg-custom-sized"
				focusable="false"
				aria-hidden="true"
				viewBox="0 0 24 24"
				data-testid="SubdirectoryArrowLeftIcon"
			>
				<path d="m11 9 1.42 1.42L8.83 14H18V4h2v12H8.83l3.59 3.58L11 21l-6-6z"></path>
			</svg>
		),
		title: "Domain Structure Analysis",
		description:
			"Use of dashes, excessive subdomains, or multiple dots in domain names",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="M236-357h60q20 0 35-15t15-35v-146q0-20-15-35t-35-15h-60q-20 0-35 15t-15 35v146q0 20 15 35t35 15Zm0-50v-146h60v146h-60Zm164 50h110q20 0 35-15t15-35v-146q0-20-15-35t-35-15H400v246Zm50-50v-146h60v146h-60Zm163 50h127q14.03 0 23.51-9.49Q773-375.98 773-390v-76q0-14-9.49-23.5-9.48-9.5-23.51-9.5h-77v-54h110v-50H646q-14.03 0-23.51 9.49Q613-584.03 613-570v78q0 14 9.49 23.5 9.48 9.5 23.51 9.5h77v52H613v50ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z" />
			</svg>
		),
		title: "Infrastructure Assessment",
		description: "Hosting provider reputation and infrastructure risks",
	},
	{
		icon: (
			<svg
				height="100%"
				width="100%"
				fill="#2fafed"
				class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dskzcr-MuiSvgIcon-root svg-custom-sized"
				focusable="false"
				aria-hidden="true"
				viewBox="0 0 24 24"
				data-testid="PhishingIcon"
			>
				<path d="M19 9c0-1.3-.84-2.4-2-2.82V2h-2v4.18C13.84 6.6 13 7.7 13 9s.84 2.4 2 2.82V15c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1h3L5 9v6c0 3.31 2.69 6 6 6s6-2.69 6-6v-3.18c1.16-.42 2-1.52 2-2.82m-3 1c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1"></path>
			</svg>
		),
		title: "Blacklist Verification",
		description: "Presence in known malware or phishing blacklists",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="M450-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h170v60H280q-58.33 0-99.17 40.76-40.83 40.77-40.83 99Q140-422 180.83-381q40.84 41 99.17 41h170v60ZM325-450v-60h310v60H325Zm185 170v-60h170q58.33 0 99.17-40.76 40.83-40.77 40.83-99Q820-538 779.17-579q-40.84-41-99.17-41H510v-60h170q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H510Z" />
			</svg>
		),
		title: "URL Analysis",
		description: "Suspicious keywords, URL structures, or redirect behaviors",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="M480-120 0-600q95-93 219-146.5T480-800q137 0 261.5 53.5T960-600l-43 43q-91-81-200-132t-237-51q-115 0-215 42T88-594l433 433-41 41Zm392-48L763-277q-18 13-39 20t-44 7q-62 0-106-44t-44-106q0-62 44-106t106-44q62 0 106 44t44 106q0 23-6.5 44T803-321l111 111-42 42ZM680-310q37 0 63.5-26.5T770-400q0-37-26.5-63.5T680-490q-37 0-63.5 26.5T590-400q0 37 26.5 63.5T680-310ZM480-161Z" />
			</svg>
		),
		title: "Obfuscation Detection",
		description:
			"Detection of obfuscation techniques such as URL shorteners, HTTPS token manipulations, and unusual IP-based domains",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="M530-80q-11 0-20.5-5.5T495-100q-5-9-5-20t6-21l170-280q6-10 15-15t19-5q10 0 19 5t15 15l170 280q6 10 6 21t-5 20q-5 9-14 14.5T870-80H530Zm170.14-35q10.86 0 18.86-8.14 8-8.15 8-19 0-10.86-8.14-18.86-8.15-8-19-8-10.86 0-18.86 8.14-8 8.15-8 19 0 10.86 8.14 18.86 8.15 8 19 8ZM317-160q-8.17 0-15.23-3.93-7.06-3.93-10.77-11.07l-84-150h71l42 80h90v-30h-71.78L296-355H191l-63-110q-1.6-3.75-2.8-7.5-1.2-3.75-1.2-7.5 0-2 4-15l63-110h105l42.22-80H410v-30h-90l-42 80h-71l84-150q3.71-7.14 10.77-11.07Q308.83-800 317-800h118q12.75 0 21.38 8.62Q465-782.75 465-770v175h-85l-30 30h115v130h-98l-39-80h-98l-30 30h108l40 80h117v199l-15 25q-3.33 4.77-5.56 10.02-2.22 5.25-4.44 10.98H317Zm359-41h48v-146h-48v146Zm-181-54v-310h115l-30-30h-85v-175q0-12.75 8.63-21.38Q512.25-800 525-800h118q8.17 0 15.23 3.93 7.06 3.93 10.77 11.07l84 150h-71l-42-80h-90v30h71.78L664-605h105l63 110q2.4 3.75 3.2 7.5.8 3.75.8 7.5 0 2-4 15l-26 46-26-43q-12-20-32-29.5t-48-9.5q-28 0-47.5 9.5T621-462L495-255Z" />
			</svg>
		),
		title: "Web Risk Assessment",
		description:
			"Web-based risks, including auto-download triggers, iframe injections, disabled right-clicking, and popup behavior",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="m436-347 228-228-42-41-183 183-101-101-44 44 142 143Zm44 266q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Zm0-62q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z" />
			</svg>
		),
		title: "SSL & Domain History",
		description:
			"SSL certificate integrity, WHOIS visibility, and domain registration history",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="m281-625 75-75-75-75-75 75 75 75Zm199-35ZM140-400q-24 0-42-18t-18-42v-400q0-24 18-42t42-18h680q24 0 42 18t18 42v400q0 24-18 42t-42 18H671q5-14 7-29t2-31h140v-400H140v400h140q0 16 2 31t7 29H140Zm520.06-285Q683-685 699-701.06q16-16.06 16-39T698.94-779q-16.06-16-39-16T621-778.94q-16 16.06-16 39T621.06-701q16.06 16 39 16ZM200-40v-84q0-35 19.5-65t51.5-45q49-23 102-34.5T480-280q54 0 107 11.5T689-234q32 15 51.5 45t19.5 65v84H200Zm60-60h440v-24q0-17.63-10-32.31Q680-171 663-179q-43-20-89-30.5T480-220q-48 0-94 10.5T297-179q-17 8-27 22.69-10 14.68-10 32.31v24Zm220-220q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.24-60q33.24 0 56.74-23.26 23.5-23.27 23.5-56.5 0-33.24-23.26-56.74-23.27-23.5-56.5-23.5-33.24 0-56.74 23.26-23.5 23.27-23.5 56.5 0 33.24 23.26 56.74 23.27 23.5 56.5 23.5Zm.24-80Zm0 360Z" />
			</svg>
		),
		title: "Interaction Analysis",
		description:
			"User interaction elements, such as webform structures, external resource dependencies, and abnormal redirects",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="100%"
				viewBox="0 -960 960 960"
				width="100%"
				fill="#2fafed"
				class="svg-custom-sized"
			>
				<path d="M100-120q-24.75 0-42.37-17.63Q40-155.25 40-180v-560h60v560h740v60H100Zm120-120q-24.75 0-42.37-17.63Q160-275.25 160-300v-520q0-24.75 17.63-42.38Q195.25-880 220-880h240l80 80h320q24.75 0 42.38 17.62Q920-764.75 920-740v440q0 24.75-17.62 42.37Q884.75-240 860-240H220Zm0-60h640v-440H515l-80-80H220v520Zm0 0v-520 520Z" />
			</svg>
		),
		title: "Content Analysis",
		description:
			"Content-based heuristics that analyze website text, metadata, and branding elements for spoofing attempts",
	},
];

const RiskAssessmentCards = () => {
	return (
		<Grid container spacing={3}>
			{riskAssessmentData.map((item, index) => (
				<Grid item xs={12} md={6} key={index}>
					<Card
						sx={{
							height: "100%",
							transition: "transform 0.2s, box-shadow 0.2s",
							"&:hover": {
								transform: "translateY(-4px)",
								boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
							},
						}}
					>
						<CardContent>
							<Box
								sx={{
									display: "flex",
									alignItems: "flex-start",
									gap: 2,
									color: "#1976d2",
								}}
							>
								{item.icon}
								<Box>
									<Typography
										variant="h6"
										gutterBottom
										class="feature-title-sub-main-title"
										sx={{
											fontWeight: 600,
											color: "#2c3e50",
										}}
									>
										{item.title}
									</Typography>
									<Typography
										variant="body1"
										sx={{
											color: "#596777",
											lineHeight: 1.6,
										}}
									>
										{item.description}
									</Typography>
								</Box>
							</Box>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};

export default RiskAssessmentCards;
