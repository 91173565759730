import axios from 'axios'

const authHeader = "";

export const getAnalysisStats = async (start_date, end_date) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/stats/`,
        {
            params: { 
                start_date: start_date,
                end_date: end_date
            },
        }
    )
}

