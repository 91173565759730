import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState, useEffect, useRef, useCallback } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Stepper from "@keyvaluesystems/react-stepper";
import { initialModules } from "../data/initialModulesData";
import usePolling from "../hooks/usePolling";
import { ProgressBarWithVariants } from "../components/common/progressBarWithVariants";
import Modal from "react-responsive-modal";

import "animate.css/animate.compat.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { sendContact } from "../api";
import ScanProgress from "../components/scan-progress";
import PreviewScanTable from "../components/preview-scan-table";

function Home() {
	const navigate = useNavigate();
	const hasRunOnce = useRef(false);

	const account = useSelector((state) => state.account);

	const [modulesList, setModulesList] = useState([]);
	const [scanProgressDone, setScanProgressDone] = useState(false);

	const [isOpenImage, setOpenImage] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isEnableNext, setEnableNext] = useState(false);
	const [isLoadingMessage, setLoadingMessage] = useState(true);
	const [isScan, setScan] = useState(false);

	const [analysUid, setAnalysUid] = useState(null);

	const [scanResult, setScanResult] = useState(
		localStorage.getItem("previous_scan")
			? JSON.parse(localStorage.getItem("previous_scan"))
			: []
	);

	const [contactState, setContactState] = useState({
		email: "",
		name: "",
		subject: "Spoofguard contact form submission",
		text: "",
	});

	const submitContact = (e) => {
		e.preventDefault();

		const formData = new FormData();

		Object.keys(contactState).forEach((key) => {
			formData.append(key, contactState[key]);
		});

		sendContact(formData)
			.then((res) => {
				enqueueSnackbar("Contact form submitted.", {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar("Could not send the form.", {
					variant: "error",
				});
			});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setContactState((prev) => ({ ...prev, [name]: value }));
	};

	const { enqueueSnackbar } = useSnackbar();
	const [scanStatus, setScanStatus] = useState(false);
	const [ip, setIp] = useState(null);

	const [totalModules, setTotalModules] = useState(0);
	const [domain, setDomain] = useState(sessionStorage.getItem("domain") || "");

	const {
		status,
		analysisId,
		setStatus: setPollingStatus,
		analysisResult,
		scanProgress,
		resultCount,
		setModuleIsLoaded,
	} = usePolling();

	const handleDomainSubmit = async (values) => {
		setLoading(true);
		setScan(false);
		setEnableNext(false);
		localStorage.removeItem("analysis_id");
		localStorage.removeItem("analysis_request");
		localStorage.removeItem("previous_scan");
		try {
			if (
				localStorage.getItem("default") === "true" &&
				!localStorage.getItem("accessToken")
			) {
				navigate("/login");
			}

			const request_body = {
				domain: domain,
				custom_domains: [],
				modules: modulesList.map((module) => ({
					module_name: module.code,
					enabled: true,
				})),
				config: {},
				keywords: [],
			};

			setLoading(true);
			setScan(true);

			const response_ip = await fetch("https://api.ipify.org?format=json");

			const ip_json = await response_ip.json();

			setIp(ip_json.ip);

			const token = await axios.get(
				`${process.env.REACT_APP_BASIC_API_URL}/token/`
			);

			const instance = axios.create({
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					"Ip-Address": ip_json.ip,
					"X-CSRFTOKEN": token,
				},
			});

			await instance
				.post(
					`${process.env.REACT_APP_BASIC_API_URL}/analysis/start/`,
					request_body
				)
				.then(async (response) => {
					console.log(response);

					setLoading(false);
					const analysisId = response.data.analysis_id;
					setAnalysUid(analysisId);

					const _data = response.data.variations.map((item) => ({
						module_name: item.module_name,
						count: item.count,
					}));

					localStorage.setItem("previous_scan", JSON.stringify(response.data));
					localStorage.setItem("analysis_id", response.data.analysis_id);
					setScanResult(_data);
					localStorage.setItem("step", 0);
					setScanStatus(true);
				})
				.catch((error) => {
					setLoading(false);
					const message =
						error.response?.data?.details ||
						error.response?.data?.domain?.[0] ||
						"Failed to load response data!";
					enqueueSnackbar(message, { variant: "error" });
				});
		} catch (error) {
			const message = "Failed to scan a domain!";
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const handleImageView = useCallback((url) => {
		setOpenImage(true);
		setPreviewImage(url);
	}, []);

	const handleImageModalClose = useCallback(() => {
		setOpenImage(false);
		setPreviewImage("");
	}, []);

	const handleRegister = () => {
		localStorage.setItem("step", 0);
		localStorage.setItem("anonymous", true);
		navigate("/register");
	};

	const handleLogin = () => {
		localStorage.setItem("step", 1);
		sessionStorage.setItem("start_scan", true);
		navigate("/login?redirecturl=/dashboard/scan");
	};

	const styles = {
		LineSeparator: () => ({ backgroundColor: "#30AFEE" }),
		ActiveNode: () => ({ backgroundColor: "#30AFEE" }),
		CompletedNode: () => ({ backgroundColor: "#30AFEE" }),
	};

	useEffect(() => {
		const getModules = async () => {
			setLoading(true);
			setPollingStatus(true);
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`
				);

				if (response) {
					const _allModules = response.data.modules.filter(
						(item) => item.code !== "module_domain_word_combination"
					);

					console.log(_allModules);
					setTotalModules(_allModules.length);
					setModulesList(_allModules);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		if (!hasRunOnce.current) {
			getModules();
			hasRunOnce.current = true;
		}
	}, [setPollingStatus]);

	useEffect(() => {
		if (scanResult.length > 0) {
			const counts = scanResult.map((item) => item.count);
			const maxCount = counts.length > 0 ? Math.max(...counts) : 0;

			const totalModulesProgress = (maxCount * 1000) / 469;

			if (scanStatus) {
				const progressInterval = setTimeout(() => {
					setEnableNext(true);
					setModuleIsLoaded(true);
					localStorage.setItem("analysis_id", analysUid);
				}, totalModulesProgress);

				return () => clearTimeout(progressInterval);
			}
		}
	}, [scanResult, scanStatus, setModuleIsLoaded, analysUid, setEnableNext]);

	useEffect(() => {
		setDomain(sessionStorage.getItem("domain"));
	}, []);

	useEffect(() => {
		if (isEnableNext && !!account && !!account.user) {
			setTimeout(() => {
				localStorage.setItem("step", 1);
				setModuleIsLoaded(true);
				navigate("/dashboard/scan");
			}, 5000);
		}
	}, [isEnableNext, account, navigate, setModuleIsLoaded]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoadingMessage(false);
		}, 12000);

		return () => clearTimeout(timeout);
	}, []);

	return (
		<>
			<div className="newbg">
				<section className="hero-section container  text-md-left">
					<div className="col-md-12 col-lg-6 hero-text">
						<div className="hero-image d-flex align-items-center">
							<p className="online-text">Domain Monitoring</p>
						</div>
						<h3>
							Security Application that Automatically Detects Phishing Sites and
							Scams
						</h3>
						<p className="w-100 w-md-100">
							Domain monitoring is crucial in protecting brands against threats
							like phishing attacks, typosquatting, and domain misuse. Our
							cost-effective solution platform ensures high-accuracy threat
							detection by identifying newly registered lookalike domains and
							analyzing suspicious patterns.
						</p>
						<div
							className="buttons-container "
							style={{ marginBottom: "40px", textAlign: "left" }}
						>
							<div className="hero-buttons">
								<div className="domain-scan-container">
									<div className="input-icon-wrapper">
										<i className="fas fa-globe"></i>
										<input
											type="text"
											className="domain-input"
											onChange={(e) => setDomain(e.target.value)}
											placeholder="Enter domain"
										/>
									</div>
									<button className="scan-button" onClick={handleDomainSubmit}>
										FREE SCAN
									</button>
								</div>
								<a
									href="https://calendly.com/spoofguard"
									target="_blank"
									rel="noreferrer"
									className="demo-button"
								>
									Book a Demo
								</a>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div style={{ textAlign: "center", width: "auto", marginTop: "-118px" }}>
				<img src="/assets/images/online/Ellipse 1.png" alt="" />
			</div>

			<main style={{ backgroundColor: "#EEF4F7", paddingTop: "30px" }}>
				{isScan && !analysUid && (
					<ScanProgress
						modules={modulesList}
						setScanProgressDone={setScanProgressDone}
					/>
				)}

				{analysUid && (
					<PreviewScanTable analysis_id={analysUid} ip_address={ip} />
				)}

				<section className="about-section " id="about">
					<div className="container">
						<div className="row">
							<div className="about-image col-md-12 col-lg-6">
								<img
									src="/assets/images/online/about-section.png"
									alt="About"
								/>
							</div>
							<div className="about-content col-md-12 col-lg-6">
								<h1>
									Why<strong> SpoofGuard</strong>
								</h1>
								<hr
									className="horizontalstyle"
									style={{ width: "70px", border: "2px solid #2fafed" }}
								/>
								<p>
									Because the others don’t cut it. We built a smarter detection
									engine to reduce false positives and keep the focus on what
									really matters. Connect any data source, tweak any module, and
									create custom rules that fit your environment—no more
									one-size-fits-all. Our algorithms aren’t generic—they’re
									constructed from real phishing patterns. We spot what others
									miss.{" "}
								</p>
								<a href="/technology" className="learn-more-btn">
									READ MORE
								</a>
							</div>
						</div>
					</div>
				</section>

				<section className="why-choose-section" id="why">
					<div className="container" style={{ textAlign: "center" }}>
						<h2>OUR TECHNOLOGY</h2>
						<h1>
							How <strong>SpoofGuard does it</strong>
						</h1>
						<hr
							style={{
								width: "70px",
								border: "2px solid #2fafed",
								margin: "20px auto",
							}}
						/>

						<div className="container" style={{ position: "relative" }}>
							<div className="steps-container">
								<div className="step">
									<div className="step-content">
										<img
											src="/assets/images/online/step1.png"
											alt="Step 1"
											className="step-img"
										/>
										<div className="step-text">
											<h2>1. Enter Your Domain</h2>
											<p>
												SpoofGuard analyzes your domain against thousands of
												typo-squatted variations, leveraging real-world insights
												to stay ahead of attackers. Our expert-curated
												permutations ensure you’re always protected by the
												latest intelligence.
											</p>
										</div>
									</div>
								</div>
								<img
									src="/assets/images/online/curve1.png"
									alt="Curve Line"
									className="curve-line"
								/>

								<div className="step reverse">
									<div className="step-content">
										<img
											src="/assets/images/online/step2.png"
											alt="Step 2"
											className="step-img"
										/>
										<div className="step-text">
											<h2>2. Detect and Analyze</h2>
											<p>
												Our powerful detection engine identifies typo-squatted
												domains, verifying DNS records and isolating threats
												with pinpoint accuracy. View active threats in real
												time, complete with isolated screenshots of malicious
												sites.
											</p>
										</div>
									</div>
								</div>
								<img
									src="/assets/images/online/curve2.png"
									alt="Curve Line"
									className="curve-line"
								/>

								<div className="step">
									<div className="step-content">
										<img
											src="/assets/images/online/step3.png"
											alt="Step 3"
											className="step-img"
										/>
										<div className="step-text">
											<h2>3. Brand Context Awareness</h2>
											<p>
												Your brand is unique—our defense is too. Share your name
												and logo, and SpoofGuard will track unauthorized use
												across scanned sites. Whether it’s text, images, or
												subtle imitations, we’ve got your brand covered.
											</p>
										</div>
									</div>
								</div>
								<img
									src="/assets/images/online/curve3.png"
									alt="Curve Line"
									className="curve-line"
								/>

								<div className="step reverse">
									<div className="step-content">
										<img
											src="/assets/images/online/Group 12.png"
											alt="Step 4"
											className="step-img"
										/>
										<div className="step-text">
											<h2>4. Proactive Monitoring</h2>
											<p>
												Stay one step ahead with real-time alerts. Activate
												monitoring for suspicious domains, and we’ll notify you
												the moment new domains surface mimicking your brand,
												keywords, or logos. With SpoofGuard, you’re never caught
												off guard.
											</p>
										</div>
									</div>
								</div>
								<img
									src="/assets/images/online/curve4.png"
									alt="Curve Line"
									className="curve-line"
								/>

								<div className="step">
									<div className="step-content">
										<img
											src="/assets/images/online/step5.png"
											alt="Step 5"
											className="step-img"
										/>
										<div className="step-text">
											<h2>5. Swift Action</h2>
											<p>
												Take control with confidence. When a malicious domain is
												confirmed, SpoofGuard works seamlessly to file takedown
												requests with hosting and DNS providers. Submit
												offending sites to blacklists directly from our
												platform. Fast, effective, and effortless.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="contact-us-section " id="contact">
					<div className="contact-us-background "></div>
					<div
						className="container-fluid"
						style={{
							display: "flex",
							padding: "0px",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<div className="contact-us-content row container">
							<div className="contact-us-right col-md-12 col-lg-12">
								<h2 className="contact-title">Contact Us</h2>

								<form
									className="contact-form"
									onSubmit={(e) => submitContact(e)}
								>
									<div className="form-row">
										<div className="form-group">
											<i className="fa fa-user icon"></i>
											<input
												type="text"
												id="name"
												name="name"
												placeholder="First Name"
												className="input-icon"
												onChange={(e) => handleInputChange(e)}
												required
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="form-group">
											<i className="fa fa-envelope icon"></i>
											<input
												type="email"
												id="email"
												name="email"
												placeholder="Email"
												className="input-icon"
												onChange={(e) => handleInputChange(e)}
												required
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="form-group">
											<i
												className="fa fa-comments icon"
												style={{ marginTop: "-78px" }}
											></i>
											<textarea
												id="text"
												name="text"
												placeholder="Leave a Message"
												className="input-icon"
												onChange={(e) => handleInputChange(e)}
												required
											></textarea>
										</div>
									</div>
									<div className="contact-us-buttons">
										<button type="submit" className="submit-button">
											Send
										</button>
										<a
											href="https://calendly.com/spoofguard"
											target="_blank"
											className="demo-button"
											rel="noreferrer"
										>
											Book a Demo
										</a>
									</div>
								</form>
							</div>
						</div>
						<hr
							style={{
								borderTop: "1px solid rgb(255 255 255)",
								width: "100%",
								marginTop: "3rem",
								opacity: "0.3",
								margin: "0px",
							}}
						/>

						{/* <div className="container">
              <section className="testimonial-slider slider" style={{ marginTop: "20px" }}>
                <Swiper
                  slidesPerView={3}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <div className="slide">
                      <img src="/assets/images/online/Layer 501.png" alt="1" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide">
                      <img src="/assets/images/online/Layer 502.png" alt="1" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide">
                      <img src="/assets/images/online/Layer 503.png" alt="1" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide">
                      <img src="/assets/images/online/Layer 504.png" alt="1" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide">
                      <img src="/assets/images/online/Layer 55.png" alt="1" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </section>
            </div> */}
					</div>
				</div>
			</main>
		</>

		// <div className="max-w-xl lg:max-w-[80rem] px-4 mx-auto py-[3rem] lg:py-[5rem]">
		//   <div className="grid grid-cols-6 lg:grid-cols-12 gap-y-8 lg:gap-0 pb-[3rem]">
		//     <div className="col-span-6">
		//       <div className="bg-hero1 lg:pt-[7vh]">
		//         <div className=" relative">
		//           <p className="text-2xl lg:text-4xl font-bold">
		//             Domain Monitoring
		//           </p>
		//           <img
		//             src={home_vector}
		//             className="absolute top-[2rem] lg:top-[2.25rem] w-[90px] lg:w-[120px]"
		//             alt=""
		//           />
		//         </div>
		//         <span className="font-bold text-2xl lg:text-4xl">
		//           <span className="text-primary">security application </span>that
		//           automatically detects{" "}
		//           <span className="text-primary">phishing sites</span> and{" "}
		//           <span className="text-primary">scams</span>
		//         </span>
		//       </div>
		//       <div className="pt-4">
		//         <p className="text-xs lg:text-sm leading-relaxed">
		//           DomainMonitor’s unique detection engine leverages 150 terabytes of
		//           structured data sets including images, text, and threat
		//           intelligence. Large language models (LLMs) train on proprietary
		//           data that addresses real-life security challenges and deliver
		//           outcomes that deliver the fastest mean time to detect (MTTD) and
		//           mean time to remediate (MTTR) phishing, impersonations, and other
		//           fraudulent online activities.
		//         </p>
		//       </div>
		//     </div>
		//     <div className="hidden lg:flex items-end">
		//       <ScrollAnimation animateIn="fadeInUp">
		//         <img src={shape} alt="" />
		//       </ScrollAnimation>
		//     </div>
		//     <div className="col-span-6 lg:col-span-5">
		//       <ScrollAnimation animateIn="fadeIn">
		//         <img src={hero} alt="" />
		//       </ScrollAnimation>
		//     </div>
		//   </div>

		//   <DomainScanForm
		//     handleDomainSubmit={handleDomainSubmit}
		//     modulesList={modulesList}
		//   />

		//   {((!isLoading && isScan) || (!isLoading && analysisId)) && (
		//     <div className="border-t-[0.5px] my-6 py-4">
		//       <div className="w-full flex justify-between items-center">
		//         <div className="w-full">
		//           <Stepper
		//             steps={steps}
		//             styles={styles}
		//             orientation="horizontal"
		//             labelPosition="bottom"
		//             currentStepIndex={currentStepIndex}
		//             onStepClick={handleStepClick}
		//           />
		//         </div>
		//       </div>
		//     </div>
		//   )}

		//   {!isLoadingMessage &&
		//     ((!isLoading && isScan) || (!isLoading && analysisId)) && (
		//       <div className="mt-5 flex items-center">
		//         <ProgressBarWithVariants
		//           totalVariants={scanProgress.totalVariants}
		//           completed={scanProgress.completed}
		//         />
		//         {scanProgress.completed === 0 && (
		//           <div className="border-[1px] border-primary p-5 rounded-xl">
		//             <p>
		//               If the scan hasn't progressed, you just have to wait longer.
		//               Our service tries to find similar domains.
		//             </p>
		//           </div>
		//         )}
		//       </div>
		//     )}

		//   {isLoadingMessage && !isLoading && analysisId && (
		//     <div className="min-h-[150px] flex justify-center items-center">
		//       <div className="">
		//         <div className="w-full flex justify-center">
		//           <RotatingLines
		//             visible={true}
		//             height="48"
		//             width="48"
		//             strokeColor="blue"
		//             strokeWidth="3"
		//             animationDuration="10"
		//             ariaLabel="rotating-lines-loading"
		//           />
		//         </div>
		//         <p className="font-bold">Please wait a moment</p>
		//       </div>
		//     </div>
		//   )}
		//   <div className="h-[3rem]"></div>
		//   {currentStepIndex === 1 && !isLoadingMessage && (
		//     <>
		//       {!isLoadingMessage && !(!!account && !!account.user) && (
		//         <>
		//           {analysisResult.length > 0 ? (
		//             <div className="mt-10 flex justify-between items-center p-4 border-primary border-2 rounded-2xl">
		//               <p className="text-black text-xl font-bold">
		//                 Partial result below. The analysis is still in progress.
		//                 Please login or register to see the full result.
		//               </p>
		//               <div className="flex gap-4">
		//                 <button
		//                   onClick={handleRegister}
		//                   className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
		//                 >
		//                   <span className="text-sm md:text-base relative z-10">
		//                     Register
		//                   </span>
		//                 </button>
		//                 <button
		//                   onClick={handleLogin}
		//                   className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
		//                 >
		//                   <span className="text-sm md:text-base relative z-10">
		//                     Login
		//                   </span>
		//                 </button>
		//               </div>
		//             </div>
		//           ) : null}

		//           <AnalysisTable
		//             currentPage={1}
		//             handleImageView={handleImageView}
		//             filteredResult={analysisResult}
		//           />
		//         </>
		//       )}
		//     </>
		//   )}

		//   {currentStepIndex === 0 && isScan && (
		//     <ShadowBlog>
		//       {isLoading && (
		//         <div className="flex flex-col justify-between">
		//           <div>
		//             <RotatingLines
		//               visible={true}
		//               height="48"
		//               width="48"
		//               color="gray"
		//               strokeWidth="3"
		//               animationDuration="10"
		//               ariaLabel="rotating-lines-loading"
		//             />
		//           </div>
		//           <p className="text-grey">
		//             Please wait as we prepare to upload the modules.
		//           </p>
		//         </div>
		//       )}

		//       <p className="pb-4 text-sm lg:text-base font-bold">
		//         Scanning for similar domains
		//       </p>

		//       {!isLoading && scanResult.length > 0 && (
		//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
		//           <div className="col-span-1">
		//             <div className="flex justify-between pb-2">
		//               <p className="text-xs lg:text-sm"></p>
		//               <p className="text-xs lg:text-sm font-semibold">
		//                 Possible Domain Variations
		//               </p>
		//             </div>
		//           </div>
		//           <div className="col-span-1">
		//             <div className="flex justify-between pb-2">
		//               <p className="text-xs lg:text-sm"></p>
		//               <p className="text-xs lg:text-sm font-semibold">
		//                 Possible Domain Variations
		//               </p>
		//             </div>
		//           </div>
		//         </div>
		//       )}

		//       <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
		//         {!isLoading &&
		//           scanResult &&
		//           scanResult.length > 0 &&
		//           scanResult.map((item, index) => (
		//             <ProgressBar
		//               key={index}
		//               label={item.module_name}
		//               total={item.count}
		//               status={scanStatus}
		//             />
		//           ))}
		//         {isLoading && (
		//           <>
		//             {initialModules.map((item, index) => (
		//               <ProgressBar
		//                 key={index}
		//                 initialModules={true}
		//                 isLoading={true}
		//                 label={item.module_name}
		//                 total={item.count}
		//                 status={scanStatus}
		//               />
		//             ))}
		//           </>
		//         )}
		//       </div>

		//       <div className="min-h-[40px]">
		//         {!isLoading && scanResult.length === 0 && (
		//           <div className="flex justify-center items-center text-grey">
		//             There is no scan result.
		//           </div>
		//         )}
		//       </div>

		//       {!isLoading && isEnableNext && scanStatus && account?.user && (
		//         <div className="flex justify-between pt-8">
		//           <p className="text-grey">
		//             The scan has finished. Please wait 5 seconds or click next to
		//             see the results.
		//           </p>
		//           <button
		//             onClick={() => (window.location.href = "/dashboard/scan")}
		//             className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
		//           >
		//             <span className="text-sm md:text-base relative z-10">Next</span>
		//           </button>
		//         </div>
		//       )}

		//       {!isLoading &&
		//         !isEnableNext &&
		//         scanStatus &&
		//         scanResult.length > 0 ? (
		//         <div className="flex justify-between items-center pt-4">
		//           <div className="flex gap-2">
		//             <RotatingLines
		//               visible={true}
		//               height="48"
		//               width="48"
		//               color="blue"
		//               strokeWidth="3"
		//               animationDuration="10"
		//               ariaLabel="rotating-lines-loading"
		//             />
		//             <div className="flex flex-col justify-between">
		//               <p className="font-bold">Please wait...</p>
		//               <p className="text-grey">
		//                 We are collecting the results. The scan can take up to 15
		//                 minutes. Please click next once the scan has finished.
		//               </p>
		//             </div>
		//           </div>
		//           <button
		//             disabled
		//             className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-grey/70 cursor-not-allowed text-white"
		//           >
		//             <span className="text-sm md:text-base relative z-10">Next</span>
		//           </button>
		//         </div>
		//       ) : (
		//         <>
		//           <button className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-grey/70 cursor-not-allowed text-white">
		//             <span className="text-sm md:text-base relative z-10">Next</span>
		//           </button>
		//         </>
		//       )}
		//     </ShadowBlog>
		//   )}

		//   <div className="h-[3rem]"></div>
		//   <Modal
		//     open={isOpenImage}
		//     onClose={handleImageModalClose}
		//     center
		//     styles={{
		//       modal: {
		//         top: "0%",
		//         transform: "translateY(-50%)",
		//         maxHeight: "90%",
		//         minHeight: "50%",
		//         minWidth: "50%",
		//         overflow: "auto",
		//         borderRadius: "30px",
		//       },
		//     }}
		//   >
		//     <img src={previewImage} className="" alt="preview" />
		//   </Modal>
		// </div>
	);
}

export default Home;
