import React from 'react';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import LockResetIcon from '@mui/icons-material/LockReset';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import DrawIcon from '@mui/icons-material/Draw';
import SecurityIcon from '@mui/icons-material/Security';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';

const StatusBadge = ({ status, isBlacklistStatus = false }) => {
    const getStatusColor = () => {
        const statusLower = status.toLowerCase();
        switch (statusLower) {
            case 'completed':
            case 'manually remediated':
                return '#66bb6a';
            case 'partially completed':
                return '#fb8c00';
            case 'in progress':
                return '#1976d2';
            case 'draft':
                return '#757575';
            case 'failed':
                return '#f44336';
            default:
                return '#757575';
        }
    };

    const getIcon = () => {
        const statusLower = status.toLowerCase();
        if (statusLower === 'in progress') {
            return isBlacklistStatus ? <AutoModeIcon sx={{ fontSize: 16 }} /> : <LockResetIcon sx={{ fontSize: 16 }} />;
        }
        if (statusLower === 'draft') {
            return <DrawIcon sx={{ fontSize: 16 }} />;
        }
        if (statusLower === 'completed' || statusLower === 'manually remediated') {
            return <SecurityIcon sx={{ fontSize: 16 }} />;
        }
        if (isBlacklistStatus && statusLower === 'partially completed') {
            return <SafetyCheckIcon sx={{ fontSize: 16 }} />;
        }
        return <CircleIcon sx={{ fontSize: 8 }} />;
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                color: getStatusColor(),
            }}
        >
            {getIcon()}
            <Typography
                variant="body2"
                component="span"
                sx={{
                    fontWeight: 500,
                }}
            >
                {status}
            </Typography>
        </Box>
    );
};

export default StatusBadge; 