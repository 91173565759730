import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Divider,
    LinearProgress,
    Tooltip,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TableBody,
    Switch,
    Link,
    Container,
    TablePagination,
    Menu,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import RadarIcon from '@mui/icons-material/Radar';
import PhishingIcon from '@mui/icons-material/Phishing';
import GavelIcon from '@mui/icons-material/Gavel';
import MonitorModal from '../../../components/new-monitor-modal';
import DashboardLayout from '../../../components/Dashboard/layout';
import { useLocation, useParams } from 'react-router-dom';
import { getExportAnalysisCsv, getExportVariationsCsv, getFurtherDomainAnalysis, getSingleDomainAnalysis, startFurtherDomainAnalysis, stopAnalysis } from '../../../api';
import { getFileFromLocalStorage, getFormattedDate, getFormattedDateTime } from '../../../utiles/general';
import DetectSpoofingModal from '../../../components/detect-spoofing-modal';
import { useSelector } from 'react-redux';
import TakedownModal from '../../../components/new-takedown-modal';
import { enqueueSnackbar } from 'notistack';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import SimilarDomainMonitorModal from '../../../components/new-similar-domain-monitor-modal';


const DomainAnalysis = () => {
    const previousDataDomainsRef = useRef(null); // To track the previous state

    const location = useLocation();
    const filteredDomain = location?.state?.filteredDomain;

    const [expanded, setExpanded] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [dataDomains, setDataDomains] = useState([]);
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(25); // Default rows per page
    const [allExpanded, setAllExpanded] = useState(false); // New state to track all rows expansion

    const isMobile = window.innerWidth <= 1440;
    const modules = useSelector((state) => state.scan.config.modules);

    const [furtherData, setFurtherData] = useState([]);
    const [furtherAnalysisDomains, setFurtherAnalysisDomains] = useState([]);
    const [completionPercentage, setCompletionPercentage] = useState(0);

    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);

    const [filters, setFilters] = useState({
        domain: filteredDomain ?? '',
        module: '',
        isNew: '',
        aRecord: '',
        mxRecord: '',
        activeWebServer: '',
        brandSpoofing: '',
        logoSpoofing: '',
        urlScore: '',
        phishing: ''
    });

    const intervalIdRef = useRef(null);

    const [monitorModalOpen, setMonitorModalOpen] = useState(false);
    const [similarDomainMonitor, setSimilarDomainMonitorModalOpen] = useState(false);

    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

    const [detectSpoofingModalOpen, setDetectSpoofingModalOpen] = useState(false);
    const [takedownModalOpen, setTakedownModalOpen] = useState(false);
    const [selectedDomainForTakedown, setSelectedDomainForTakedown] = useState(null);

    const { analysis_id } = useParams();

    useEffect(() => {
        setExpandedRow(filteredDomain)
    }, [filteredDomain])

    useEffect(() => {
        if (data || furtherData) {
            const detectedDomainsCount = data.dns_lookups_formatted?.length || 0;
            const webServers = data.dns_lookups_formatted?.filter((domain) => domain.https_open || domain.http_open).length;
            const brandSpoofing = furtherData?.filter((domain) => domain.spoofing).length;
            const logoSpoofing = furtherData?.filter((domain) => domain.logo_spoofing).length;

            setStats([
                { label: 'Generated Domain Variations', value: data.total_variations },
                { label: 'Detected Domains', value: detectedDomainsCount },
                { label: 'Web Servers Found', value: webServers },
                { label: 'Brand Spoofing', value: brandSpoofing },
                { label: 'Logo Spoofing', value: logoSpoofing }
            ]);
        }
    }, [data, furtherData]);


    useEffect(() => {

        const fetchData = () => {
            if (analysis_id) {
                getSingleDomainAnalysis(analysis_id)
                    .then((response) => {
                        const resultData = response?.data;
                        setData(resultData);
                        setDataDomains(resultData.dns_lookups_formatted)

                        setCompletionPercentage(
                            Math.round((resultData.total_initial_done / resultData.total_variations) * 100)
                        );

                        if ((resultData.total_initial_done === resultData.total_variations) || resultData.status === "stopped") {
                            clearInterval(intervalIdRef.current);
                            setLoading(false)
                            setCompletionPercentage(0)
                        }

                        if (resultData.status === "further_running") {
                            const fetchData = () => {
                                if (analysis_id) {
                                    getFurtherDomainAnalysis(analysis_id)
                                        .then((response) => {
                                            const resultData = response?.data;
                                            setFurtherData(resultData.further_results_formatted)
                                            setFurtherAnalysisDomains(resultData.further_analysis_domains)

                                            setCompletionPercentage(
                                                Math.round((resultData.total_further_done / resultData.total_further) * 100)
                                            );

                                            if (resultData.status === "further_complete") {
                                                clearInterval(intervalIdRef.current);
                                                setFurtherData(resultData.further_results_formatted)
                                                setFurtherAnalysisDomains(resultData.further_analysis_domains)
                                                setLoading(false)
                                                setCompletionPercentage(0)
                                            }
                                        })
                                        .catch((err) => {
                                            enqueueSnackbar("Unable to load further analysis", {
                                                variant: "error",
                                            });
                                        });
                                }
                            };

                            intervalIdRef.current = setInterval(fetchData, 2000);
                            return () => clearInterval(intervalIdRef.current);
                        } else if (resultData.status === "further_complete") {
                            getFurtherDomainAnalysis(analysis_id)
                                .then((response) => {
                                    const resultData = response?.data;
                                    setFurtherData(resultData.further_results_formatted)
                                    setFurtherAnalysisDomains(resultData.further_analysis_domains)

                                    setCompletionPercentage(
                                        Math.round((resultData.total_further_done / resultData.total_further) * 100)
                                    );

                                    if (resultData.status === "further_complete") {
                                        setFurtherData(resultData.further_results_formatted)
                                        setFurtherAnalysisDomains(resultData.further_analysis_domains)
                                        setLoading(false)
                                        setCompletionPercentage(0)
                                    }
                                })
                                .catch((err) => {
                                    enqueueSnackbar("Unable to load further analysis", {
                                        variant: "error",
                                    });
                                });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        };

        intervalIdRef.current = setInterval(fetchData, 2000);

        return () => clearInterval(intervalIdRef.current);
    }, [analysis_id]);


    useEffect(() => {
        if (!dataDomains || !furtherData) return;

        const updatedData = dataDomains.map(item => {
            const correspondingFurtherItem = furtherData.find(further => further.domain === item.domain);

            if (correspondingFurtherItem) {
                return {
                    ...item,
                    ...correspondingFurtherItem
                };
            }

            return item;
        });

        const newItems = furtherData.filter(
            furtherItem => !updatedData.some(item => item.domain === furtherItem.domain)
        );

        const finalData = [...updatedData, ...newItems];

        if (!isEqual(previousDataDomainsRef.current, finalData)) {
            setDataDomains(finalData);
            previousDataDomainsRef.current = finalData;
        }
    }, [furtherData, dataDomains]);

    // useEffect(() => {
    //     return () => {
    //         deleteFileFromLocalStorage(brandLogoKey);
    //         dispatch(resetBrand())
    //     };
    // }, [dispatch, brandLogoKey]);

    // Helper function to deep compare objects
    function isEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const handleRowExpand = (domain) => {
        setExpandedRow(prevExpandedRow => prevExpandedRow === domain ? null : domain);
    };

    const handleRowSelect = (domain) => {
        setSelectedRows(prev =>
            prev.includes(domain) ? prev.filter(d => d !== domain) : [...prev, domain]
        );
    };

    const handleFilterChange = (column, value) => {
        setFilters(prev => {
            const newFilters = { ...prev, [column]: value };
            return newFilters;
        });
    };

    const filteredDomains = useMemo(() => {
        if (!dataDomains) return [];

        return dataDomains.filter(domain => {
            const {
                domain: dFilter,
                module,
                isNew,
                nsRecord,
                aRecord,
                mxRecord,
                activeWebServer,
                brandSpoofing,
                logoSpoofing,
                urlScore,
                phishing
            } = filters;

            // Domain filter
            if (dFilter && !domain.domain?.toLowerCase().includes(dFilter.toLowerCase())) return false;

            // Module filter
            if (module && domain.module_name !== module) return false;

            // is new
            if (isNew) {
                const val = domain.is_new ? 'true' : 'false';
                console.log(val)
                if (val !== isNew) return false;
            }

            // nsRecord
            if (nsRecord) {
                const val = domain.ns_record === 'N/A' ? 'false' : 'true';
                if (val !== nsRecord) return false;
            }

            // nsRecord
            if (aRecord) {
                const val = domain.a_record === 'N/A' ? 'false' : 'true';
                if (val !== aRecord) return false;
            }

            // nsRecord
            if (mxRecord) {
                const val = domain.mx_record === 'N/A' ? 'false' : 'true';
                if (val !== mxRecord) return false;
            }

            // activeWebServer
            if (activeWebServer) {
                const isActive = (domain.https_open || domain.http_open) ? 'true' : 'false';
                if (isActive !== activeWebServer) return false;
            }

            if (phishing) {
                const isActive = Object.keys(domain.phishing).length > 0 ? 'true' : 'false';
                if (isActive !== phishing) return false;
            }

            // brandSpoofing
            if (brandSpoofing) {
                const val = domain.spoofing === 'N/A' ? 'N/A' : domain.spoofing ? 'true' : 'false';
                if (val !== brandSpoofing) return false;
            }

            // logoSpoofing
            if (logoSpoofing) {
                const val = domain.logo_spoofing === 'N/A' ? 'N/A' : domain.logo_spoofing ? 'true' : 'false';
                if (val !== logoSpoofing) return false;
            }

            // urlScore
            const aVal = (domain.urlscore === 'N/A' || domain.urlscore === undefined) ? -Infinity : domain.urlscore;
            if (urlScore === 'N/A' && domain.urlscore !== 'N/A') return false;
            if ((urlScore === 'High to Low' || urlScore === 'Low to High') && domain.urlscore === 'N/A') return false;



            return true;
        });
    }, [dataDomains, filters]);

    // Determine effective urlScore sorting if none is chosen
    const effectiveUrlScoreSort = filters.urlScore || 'High to Low';

    const sortedDomains = useMemo(() => {
        // Sort by urlScore first, as before
        let arr = [...filteredDomains].sort((a, b) => {
            const aVal = (a.urlscore === 'N/A' || a.urlscore === undefined) ? -Infinity : a.urlscore;
            const bVal = (b.urlscore === 'N/A' || b.urlscore === undefined) ? -Infinity : b.urlscore;

            if (effectiveUrlScoreSort === 'High to Low') {
                return bVal - aVal;
            } else if (effectiveUrlScoreSort === 'Low to High') {
                return aVal - bVal;
            }
            return bVal - aVal; // Default to high to low if none specified
        });

        // After sorting by url score, bring any domains with http_open or https_open to the top
        arr = arr.sort((a, b) => {
            const aHasOpen = a.http_open === true || a.https_open === true;
            const bHasOpen = b.http_open === true || b.https_open === true;

            // Domains with http_open or https_open should appear first
            if (aHasOpen && !bHasOpen) return -1;
            if (!aHasOpen && bHasOpen) return 1;
            return 0;
        });

        return arr;
    }, [filteredDomains, effectiveUrlScoreSort]);

    const paginatedDomains = useMemo(() => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return sortedDomains.slice(startIndex, endIndex);
    }, [sortedDomains, page, rowsPerPage]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleTakedownRequest = (domain) => {
        setSelectedDomainForTakedown(domain);
        setTakedownModalOpen(true);
    };

    const handleDetectSpoofingClick = async (modalData = null) => {
        let logoId = modalData ? modalData.logoId : data?.logo?.id;
        let brandingId = modalData ? modalData.brandId : data?.branding ? data?.branding?.id : null;
        let spoofingOp = modalData ? modalData.spoofingOp : "and";

        if (logoId || brandingId) {

            const data = {
                branding_id: brandingId ? parseInt(brandingId) : null,
                urlscore_scan: true,
                spoofing: brandingId ? true : false,
                domains: selectedRows,
                logo_scan: logoId ? true : false,
                logo_id: logoId ? parseInt(logoId) : null,
                spoofing_op: spoofingOp
            }

            try {
                setLoading(true)
                const response = await startFurtherDomainAnalysis(analysis_id, data);

                if (response.status === 200) {
                    const fetchData = () => {
                        if (analysis_id) {
                            getFurtherDomainAnalysis(analysis_id)
                                .then((response) => {
                                    const resultData = response?.data;
                                    setFurtherData(resultData.further_results_formatted)
                                    setFurtherAnalysisDomains(resultData.further_analysis_domains)

                                    setCompletionPercentage(
                                        Math.round((resultData.total_further_done / resultData.total_further) * 100)
                                    );

                                    if (resultData.status === "further_complete" || resultData.status === "stopped") {
                                        clearInterval(intervalIdRef.current);
                                        setLoading(false)
                                        setCompletionPercentage(0);
                                    }
                                })
                                .catch((err) => {
                                    enqueueSnackbar("Unable to start further analysis", {
                                        variant: "error",
                                    });

                                    setLoading(false)

                                });
                        }
                    };

                    intervalIdRef.current = setInterval(fetchData, 2000);
                    return () => clearInterval(intervalIdRef.current);
                }

            } catch (error) {
                enqueueSnackbar("Unable to start further analysis", {
                    variant: "error",
                });

                setLoading(false)
            }
        } else {
            setDetectSpoofingModalOpen(true)
        }
    }

    const monitorsEnabled = (showLoading) => {
        setLoading(showLoading);
        setMonitorModalOpen(false)
    }

    const handleStopScan = () => {
        stopAnalysis(analysis_id).then(() => {
            setLoading(false);
            clearInterval(intervalIdRef.current);
        })
    }

    const handleExportDomainVariations = async () => {
        try {
            const response = await getExportVariationsCsv(analysis_id);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'domain_variations.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error exporting domain variations:", error);
        }
    };

    const handleExportDomainAnalysis = async () => {
        try {
            const response = await getExportAnalysisCsv(analysis_id);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'domain_analysis.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error exporting domain analysis:", error);
        }
    };

    return (
        <DashboardLayout>
            <Container maxWidth="100%" sx={{
                pt: 3,
            }}>
                <Box sx={{ p: 1, bgcolor: '#F7F8FB', minHeight: '100vh' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Box>
                            <Typography variant="h5">Analyzed results for {data.domain}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {getFormattedDateTime(data?.created_at)}
                            </Typography>
                        </Box>
                        <Box>
                            <Button startIcon={<DownloadIcon />} variant="outlined" sx={{ mr: 1 }} onClick={() => handleExportDomainVariations()}>
                                EXPORT DOMAIN VARIATIONS
                            </Button>
                            <Button startIcon={<DownloadIcon />} variant="outlined" onClick={() => handleExportDomainAnalysis()}>
                                EXPORT DOMAIN ANALYSIS
                            </Button>
                        </Box>
                    </Box>

                    <Paper sx={{ p: 3, mb: 0 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            {stats.map((stat, index) => (
                                <React.Fragment key={stat.label}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="h4" fontWeight="bold">{stat.value}</Typography>
                                        <Typography color="text.secondary">{stat.label}</Typography>
                                    </Box>
                                    {index < stats.length - 1 && <Divider orientation="vertical" flexItem />}
                                </React.Fragment>
                            ))}
                        </Box>
                    </Paper>

                    <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                        {loading &&
                            <Box sx={{ display: 'flex', width: '100%', mb: 0, mt: 2, alignItems: 'center' }}>
                                <LinearProgress sx={{ flexGrow: 1, mr: 2 }} />
                                <Typography variant="body2" mr={2} color="text.secondary">
                                    {completionPercentage}% Completed
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleStopScan}
                                >
                                    Stop Scan
                                </Button>
                            </Box>
                        }
                    </Box>

                    {/* <Accordion expanded={expandedHowTo} onChange={handleAccordionHowToChange} sx={{ mb: 1 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="view-scanned-modules-content"
                            id="view-scanned-modules-header"
                        >
                            <Typography>Expand to View Available Actions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Paper elevation={0} sx={{ p: 1, textAlign: 'left', bgcolor: 'background.paper' }}>
                                <Typography variant="body2" mb={1}>After the initial scan, your domain permutations are displayed in the table below with NS-records, A-records, and MX-records. You can take the following actions on detected domains:</Typography>
                                <Typography variant="body2"><strong>Detect Spoofing:</strong> Analyze domains to compare branding and logos for impersonation and receive a risk score using <a href='https://urlscore.ai'>urlscore.ai.</a> Completed analyses are highlighted in yellow.</Typography>
                                <Typography variant="body2"><strong>Set Up Monitoring:</strong> Track changes in DNS records, detect phishing database listings, or monitor unauthorized use of branding and logos.</Typography>
                                <Typography variant="body2" mb={1}><strong>Takedown:</strong> Expand a row and select the takedown option to start removing malicious domains.</Typography>
                                <Typography variant="body2">You can perform any combination of these actions as needed to protect your brand effectively. </Typography>
                            </Paper>
                        </AccordionDetails>
                    </Accordion> */}

                    <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{ mb: 2 }}>
                        <AccordionSummary
                            //expandIcon={<ExpandMoreIcon />}
                            aria-controls="view-scanned-modules-content"
                            id="view-scanned-modules-header"
                        >
                            <ExpandMoreIcon />
                            <Typography>View Scan Modules</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ margin: 0, backgroundColor: '#F7F8FB' }}>
                                <Grid container spacing={1} sx={{ mb: 3, p: 2, borderRadius: 1 }}>
                                    {data?.params?.modules.map((module, index) => {
                                        const tooltip = modules?.filter(x => x.name === module.module_name)[0];
                                        return (
                                            <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                                <Tooltip title={tooltip?.description}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        p: 1,
                                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                                        borderRadius: 1,
                                                        minHeight: '40px',
                                                        textAlign: 'center',
                                                        fontSize: '0.75rem',
                                                        lineHeight: 1.2,
                                                        wordBreak: 'break-word',
                                                        backgroundColor: 'white'
                                                    }}>
                                                        <Typography variant="caption">{module.module_name}</Typography>
                                                    </Box>
                                                </Tooltip>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <TableContainer className='analysis-table' component={Paper} sx={{ maxHeight: '100vh', minHeight: "500px", overflow: 'auto' }}>
                        <Table>
                            <TableHead sx={{ position: "sticky", top: "0", backgroundColor: "#fff", zIndex: 99 }}>
                                <TableRow p={0}>
                                    <TableCell p={1} colSpan={11}>
                                        <Box sx={{ p: 0, display: 'flex', justifyContent: 'space-between', bgcolor: 'white' }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Switch
                                                    checked={allExpanded}
                                                    onChange={() => setAllExpanded(prev => !prev)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                <Typography variant="subtitle1">Expand all</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip mr={4} title={
                                                    <>
                                                        <Typography variant="body2" mb={1}>After the initial scan, your domain permutations are displayed in the table below with NS-records, A-records, and MX-records. You can take the following actions on detected domains:</Typography>
                                                        <Typography variant="body2"><strong>Detect Spoofing:</strong> Analyze domains to compare branding and logos for impersonation and receive a risk score using <a href='https://urlscore.ai'>urlscore.ai.</a> Completed analyses are highlighted in yellow.</Typography>
                                                        <Typography variant="body2"><strong>Set Up Monitoring:</strong> Track changes in DNS records, detect phishing database listings, or monitor unauthorized use of branding and logos.</Typography>
                                                        <Typography variant="body2" mb={1}><strong>Takedown:</strong> Expand a row and select the takedown option to start removing malicious domains.</Typography>
                                                        <Typography variant="body2">You can perform any combination of these actions as needed to protect your brand effectively. </Typography>
                                                    </>
                                                }>

                                                    <Typography variant="subtitle1">
                                                        <IconButton size="small" sx={{ mr: 1 }}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                        View Available Actions</Typography>
                                                </Tooltip>

                                                <Tooltip title={selectedRows.length === 0 ? "Select at least one domain to detect spoofing" : ""} arrow>
                                                    <span>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                mr: 2,
                                                                bgcolor: '#FFC985',
                                                                color: 'black',
                                                                '&:hover': { bgcolor: '#E6B578' },
                                                                '& .MuiSvgIcon-root': { color: 'black' }
                                                            }}
                                                            startIcon={<PhishingIcon />}
                                                            disabled={selectedRows.length === 0}
                                                            onClick={() => handleDetectSpoofingClick()}
                                                        >
                                                            DETECT SPOOFING
                                                        </Button>
                                                    </span>
                                                </Tooltip>

                                                <PopupState variant="popover" popupId="demo-popup-menu">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <Button variant="contained" {...bindTrigger(popupState)}>
                                                                SETUP MONITORING
                                                            </Button>
                                                            <Menu {...bindMenu(popupState)}>
                                                                <MenuItem disabled={selectedRows.length === 0} onClick={() => setMonitorModalOpen(true)}>
                                                                    Static List
                                                                </MenuItem>
                                                                <MenuItem onClick={() => setSimilarDomainMonitorModalOpen(true)}>
                                                                    Live Scan
                                                                </MenuItem>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>


                                                {/* <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ mr: 2 }}
                                                    startIcon={<RadarIcon />}
                                                    disabled={selectedRows.length === 0}
                                                    onClick={() => setMonitorModalOpen(true)}
                                                >
                                                    SETUP MONITORING
                                                </Button> */}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#ffffff' }}>
                                    <TableCell padding="checkbox" sx={{ width: '48px', maxWidth: "48px" }}>
                                        <Checkbox
                                            indeterminate={selectedRows.length > 0 && selectedRows.length < sortedDomains.length}
                                            checked={selectedRows.length === sortedDomains.length}
                                            onChange={() => {
                                                if (selectedRows.length === sortedDomains.length) {
                                                    setSelectedRows([]);
                                                } else {
                                                    setSelectedRows(sortedDomains.map(d => d.domain));
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ width: '15%' }}>
                                        <Tooltip title={"This section highlights domains that appear similar to the input domain. Our system generates thousands of potential domain variations by leveraging typo-squatting techniques and analyzing external sources like new domain registrations and SSL transparency logs."} arrow>
                                            Discovered Domains
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ width: '15%' }}>
                                        <Tooltip title={"This section explains how we discovered each similar domain."} arrow>
                                            Enumeration Technique
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }}>
                                        <Tooltip title={"Flags domains with newly detected NS records or recent registrations in SSL transparency logs and domain databases."} arrow>
                                            Is New
                                        </Tooltip>
                                    </TableCell>

                                    <TableCell sx={{ width: '10%' }}>
                                        <Tooltip title={"If we find that a domain has an active NS record, we proceed to check for an A record."} arrow>
                                            A Record
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }}>
                                        <Tooltip title={"If a domain has an NS record, we also check for the presence of an MX (Mail Exchange) record."} arrow>
                                            MX Record
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }}>

                                        <Tooltip title={"For domains with an A record, we also check for the presence of a web server."} arrow>
                                            Web
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }}>Phishing</TableCell>

                                    <TableCell sx={{ width: '10%' }}>Brand Spoofing</TableCell>
                                    <TableCell sx={{ width: '10%' }}>Logo Spoofing</TableCell>
                                    <TableCell sx={{ width: '10%' }}>URL Score</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#ffffff' }}>
                                    <TableCell padding="checkbox" sx={{ width: '48px' }} />
                                    <TableCell sx={{ width: '15%', p: 1 }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Filter by Domain"
                                            value={filters.domain}
                                            onChange={(e) => handleFilterChange('domain', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ width: '15%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.module}
                                                onChange={(e) => handleFilterChange('module', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                {Array.from(new Set(data?.dns_lookups_formatted?.map(lookup => lookup.module_name))).map(option => (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.isNew}
                                                onChange={(e) => handleFilterChange('isNew', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>

                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.aRecord}
                                                onChange={(e) => handleFilterChange('aRecord', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.mxRecord}
                                                onChange={(e) => handleFilterChange('mxRecord', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.activeWebServer}
                                                onChange={(e) => handleFilterChange('activeWebServer', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>

                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.phishing}
                                                onChange={(e) => handleFilterChange('phishing', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>

                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.brandSpoofing}
                                                onChange={(e) => handleFilterChange('brandSpoofing', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.logoSpoofing}
                                                onChange={(e) => handleFilterChange('logoSpoofing', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%', p: 1 }}>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={filters.urlScore}
                                                onChange={(e) => handleFilterChange('urlScore', e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="N/A">N/A</MenuItem>
                                                <MenuItem value="High to Low">High to Low</MenuItem>
                                                <MenuItem value="Low to High">Low to High</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedDomains.map((row) => (
                                    <React.Fragment key={row.domain}>
                                        <TableRow
                                            sx={{
                                                cursor: "pointer",
                                                backgroundColor: furtherData?.filter(x => x.domain === row.domain).length > 0 ? "#fae9d4" : selectedRows.includes(row.domain) ? '#D6EFFC' : 'background.paper',
                                                '&:hover': { backgroundColor: selectedRows.includes(row.domain) ? '#D6EFFC' : '#f5f5f5' }
                                            }}

                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRowExpand(row.domain);
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.domain)}
                                                        onChange={() => handleRowSelect(row.domain)}
                                                        onClick={(e) => e.stopPropagation()}
                                                    />
                                                    <IconButton
                                                        size="small"
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    href={`http://${row.domain}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{ color: 'inherit', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    {row.domain}
                                                </Link>
                                            </TableCell>
                                            <TableCell>{row.module_name}</TableCell>
                                            <TableCell>{row.is_new ? "True" : "False"}</TableCell>
                                            <TableCell>{row.a_record}</TableCell>
                                            <TableCell>
                                                {(isMobile && row.mx_record !== "N/A")
                                                    ? <Tooltip title={row.mx_record}>
                                                        {row.mx_record}
                                                    </Tooltip>
                                                    : <>{row.mx_record}</>
                                                }

                                            </TableCell>
                                            <TableCell>{row.https_open || row.http_open ? 'True' : 'False'}</TableCell>
                                            <TableCell>{Object.keys(row.phishing).length > 0 ? 'True' : 'False'}</TableCell>
                                            <TableCell>{row.hasOwnProperty("spoofing") ? row.spoofing === 'N/A' ? 'N/A' : (row.spoofing ? "True" : "False") : "N/A"}</TableCell>
                                            <TableCell>{row.hasOwnProperty("logo_spoofing") ? row.logo_spoofing === 'N/A' ? 'N/A' : (row.logo_spoofing ? "True" : "False") : "N/A"}</TableCell>
                                            <TableCell>{row.urlscore === 0 ? row.urlscore : row.urlscore > 0 ? row.urlscore : "N/A"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={11} sx={{ p: 0, border: 0 }}>
                                                <Accordion
                                                    expanded={allExpanded || expandedRow === row.domain}
                                                    onChange={() => handleRowExpand(row.domain)}
                                                    sx={{
                                                        '&.MuiAccordion-root': {
                                                            '&:before': {
                                                                display: 'none',
                                                            },
                                                        },
                                                        boxShadow: 'none',
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        sx={{ display: 'none' }}
                                                    />
                                                    <AccordionDetails sx={{ backgroundColor: '#F7F8FB', p: 0 }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '400px' }}>

                                                            <Box sx={{ display: 'flex', flex: 1 }}>
                                                                {/* Left Section: Screenshot placeholder */}
                                                                <Box sx={{
                                                                    width: '33.33%',
                                                                    p: 3,
                                                                    borderRight: '1px solid #e0e0e0',
                                                                    display: 'flex',
                                                                    flexDirection: 'column'
                                                                }}>
                                                                    <Typography variant="h6" gutterBottom>Screenshot</Typography>
                                                                    {/* <Box sx={{
                                                                        flex: 1,
                                                                        backgroundColor: '#e0e0e0',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        minHeight: '300px',
                                                                        overflow: 'auto'
                                                                    }}>
                                                                        <Typography>Screenshot Placeholder</Typography>
                                                                    </Box> */}

                                                                    <Box sx={{ width: "100%", maxHeight: "30vh", minHeight: "30vh", overflow: "auto" }}>
                                                                        {row.screenshot ? (
                                                                            <img
                                                                                src={row.screenshot}
                                                                                alt="Domain Screenshot"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "auto",
                                                                                    borderRadius: 4,
                                                                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                                                }}
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null; // Prevent infinite error loop
                                                                                    e.target.style.display = "none"; // Hide the broken image
                                                                                    e.target.parentNode.innerHTML = `
                                                                                    <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; border: 1px dashed #ccc; border-radius: 4px; font-weight: bold; color: #999;">
                                                                                    No screenshot available
                                                                                    </div>
                                                                                    `;
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <Box
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    border: "1px dashed #ccc",
                                                                                    borderRadius: 4,
                                                                                    fontWeight: "bold",
                                                                                    color: "#999",
                                                                                }}
                                                                            >
                                                                                No screenshot available
                                                                            </Box>
                                                                        )}
                                                                    </Box>

                                                                </Box>

                                                                {/* Center Section: Detailed Information */}
                                                                <Box sx={{ width: '33.33%', p: 3, borderRight: '1px solid #e0e0e0' }}>
                                                                    <Typography variant="h6" gutterBottom>Detailed Information</Typography>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                                        <Typography><strong>IP Address:</strong> {row.a_record}</Typography>
                                                                        {row.ns_record &&
                                                                            <Typography><strong>NS Record:</strong> {row.ns_record}</Typography>
                                                                        }
                                                                        <Typography><strong>HTTPS Open:</strong> {row.https_open ? 'Yes' : 'No'}</Typography>
                                                                        <Typography><strong>HTTP Open:</strong> {row.http_open ? 'Yes' : 'No'}</Typography>
                                                                        {row.whois_formatted?.creation_date &&
                                                                            <Typography><strong>Domain Creation Date:</strong> {getFormattedDate(row.whois_formatted?.creation_date)}</Typography>
                                                                        }
                                                                        {row.whois_formatted?.expiration_date &&
                                                                            <Typography><strong>Domain Expiration Date:</strong> {getFormattedDate(row.whois_formatted?.expiration_date)}</Typography>
                                                                        }
                                                                        <Typography><strong>Registrar:</strong> {row.whois_formatted?.registrar ? row.whois_formatted?.registrar : "N/A"}</Typography>
                                                                        <Typography><strong>Country:</strong> {row.whois_formatted?.country ? row.whois_formatted?.country : "N/A"}</Typography>
                                                                        <Typography><strong>Address:</strong> {row.whois_formatted?.address ? row.whois_formatted?.address : "N/A"}</Typography>
                                                                        {row.whois_formatted?.abuse_email &&
                                                                            <Typography sx={{ wordWrap: "break-word" }}><strong>Abuse Email:</strong> {Array.isArray(row.whois_formatted?.abuse_email) ? row.whois_formatted?.abuse_email.join(', ') : row.whois_formatted?.abuse_email}</Typography>
                                                                        }
                                                                        {row.phishing &&
                                                                            <Typography><strong>Phishing Database:</strong> {row.phishing.db}</Typography>
                                                                        }
                                                                    </Box>
                                                                </Box>

                                                                {/* Right Section: URL Score Information */}
                                                                <Box sx={{ width: '33.33%', p: 3 }}>

                                                                    <Box sx={{ width: "100%", maxHeight: "30vh", minHeight: "30vh", overflow: "auto" }}>

                                                                        <Typography variant="h6" gutterBottom>Risk Score Information</Typography>
                                                                        {row.urlscore_data ? (
                                                                            <>
                                                                                {row.urlscore_data?.checks?.filter(x => x.risk_score).map((check) => (
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                                                                                        <Typography variant='subtitle1'><strong>{check.category}</strong> - <strong>Risk score: {check.risk_score}</strong></Typography>

                                                                                        {check.details.filter(x => x.risk_score).map((detail) => (
                                                                                            <Box mb={3}>
                                                                                                <Typography sx={{ display: "flex", alignItems: "center" }}>
                                                                                                    <strong>{detail.name}</strong>
                                                                                                    <Tooltip title={detail.background_info}>
                                                                                                        <IconButton size="small">
                                                                                                            <InfoIcon />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </Typography>
                                                                                                <Typography mt={1}>{detail.message}</Typography>
                                                                                            </Box>
                                                                                        ))}

                                                                                    </Box>

                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <Typography>URL Score data is unavailable for this domain. Select the domain and click "Detect Spoofing" to generate the results.</Typography>
                                                                        )}
                                                                    </Box>


                                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, mt: 1 }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{
                                                                                bgcolor: '#CD6B6B',
                                                                                '&:hover': { bgcolor: '#B25757' },
                                                                                color: 'white',
                                                                            }}
                                                                            startIcon={<GavelIcon />}
                                                                            onClick={() => handleTakedownRequest(row.domain)}
                                                                        >
                                                                            Request Takedown
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={filteredDomains.length} // Total rows count
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={[25, 50, 100]}
                        />
                    </TableContainer>
                    <SimilarDomainMonitorModal
                        open={similarDomainMonitor}
                        onClose={() => setSimilarDomainMonitorModalOpen(false)}
                        selectedDomains={selectedRows}
                        onMonitorsEnabled={monitorsEnabled}
                        analysis={data}
                    />
                    <MonitorModal
                        open={monitorModalOpen}
                        onClose={() => setMonitorModalOpen(false)}
                        selectedDomains={selectedRows}
                        onMonitorsEnabled={monitorsEnabled}
                        analysis={data}
                    />
                    <DetectSpoofingModal
                        open={detectSpoofingModalOpen}
                        onClose={() => setDetectSpoofingModalOpen(false)}
                        onSubmit={(data) => handleDetectSpoofingClick(data)}
                        selectedDomains={selectedRows}
                    />
                    <TakedownModal
                        open={takedownModalOpen}
                        onClose={() => setTakedownModalOpen(false)}
                        domain={selectedDomainForTakedown}
                        originalDomain="visvang.xyz"
                        scanData={data}
                    />
                </Box>

            </Container>
        </DashboardLayout>
    );
}

export default DomainAnalysis;

