import React, { useState, useEffect } from 'react';
import {
    TableRow,
    TableCell,
    IconButton,
    Collapse,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendIcon from '@mui/icons-material/Send';
import { getFormattedDateTime } from '../../utiles/general';
import StatusBadge from '../common/statusBadge';
import ActionButtons from '../common/actionButton';
import TakedownExpandedContent from '../takedown-expanded-content';
import { useNavigate } from 'react-router-dom';
import { sendTakeDown } from '../../api';
import { enqueueSnackbar } from 'notistack';

const TakedownRow = ({
    row,
    expanded,
    onExpand,
    onSendTakedown,
    onAdditionalInfoChange,
    onTakeDownDelete,
}) => {
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const navigate = useNavigate();

    const [localRow, setLocalRow] = useState(row);
    const [isSendingTakedown, setIsSendingTakeDown] = useState(false);

    useEffect(() => {
        setLocalRow(row);
    }, [row]);

    const handleDelete = (e) => {
        e.stopPropagation();
        onTakeDownDelete(localRow);
    };

    const handleSendTakedown = (e) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
    };

    const handleConfirmSend = () => {
        setOpenConfirmDialog(false);
        setIsSendingTakeDown(true);


        sendTakeDown(row.uid, {})
            .then((response) => {
                const data = response?.data;

                enqueueSnackbar("Takedown sent successfully", {
                    variant: "success",
                });
                setIsSendingTakeDown(false);
                setLocalRow(data);

            })
            .catch((error) => {
                if (error?.response?.data?.details) {
					enqueueSnackbar(
						error?.response?.data?.details,
						{
							variant: "error",
						}
					);
				} else {
					enqueueSnackbar("An error has occured", {
						variant: "error",
					});
				}

                setIsSendingTakeDown(false);
            });
    };

    const handleViewScan = (e) => {
        e.stopPropagation();
        console.log('Viewing scan:', localRow.id);
        navigate(`/dashboard/domain-analysis/${localRow?.analysis_id}`, {
            state: { filteredDomain: localRow?.malicious_domain },
        });
    };

    const isDraft = localRow.status.toLowerCase() === 'draft';

    const getBlacklistsStatus = () => {
        const totalEnabled = localRow.blacklists?.length || 0;
        if (totalEnabled === 0) return 'Not Requested';
        if (localRow.status.toLowerCase() === 'draft') return 'Draft';

        if (localRow.status.toLowerCase() === 'completed') {
            const isAnyProviderCompleted = localRow.providerData?.some(
                (provider) =>
                    provider.status.toLowerCase() === 'completed' ||
                    provider.status.toLowerCase() === 'manually remediated'
            );

            const isAnyBlacklistCompleted = localRow.blacklistData?.some(
                (blacklist) =>
                    blacklist.status.toLowerCase() === 'completed' ||
                    blacklist.status.toLowerCase() === 'manually remediated'
            );

            if (isAnyProviderCompleted && isAnyBlacklistCompleted) {
                return 'Partially Completed';
            }

            return 'Completed';
        }

        return 'In Progress';
    };

    const handleTakedownChange = (updatedMonitor) => {
        setLocalRow(updatedMonitor);
    };

    return (
        <>
            <TableRow
                hover
                onClick={onExpand}
                sx={{
                    backgroundColor: expanded ? 'rgba(0, 0, 0, 0.05)' : 'inherit',
                    '&:nth-of-type(odd)': {
                        backgroundColor: expanded
                            ? 'rgba(0, 0, 0, 0.05)'
                            : 'rgba(0, 0, 0, 0.05)',
                    },
                    '& > td': {
                        height: '40px',
                        padding: '0 16px',
                    },
                    borderBottom: 'none',
                    cursor: 'pointer',
                }}
            >
                <TableCell padding="none" sx={{ width: '48px', pl: 1 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onExpand();
                        }}
                    >
                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{localRow.malicious_domain}</TableCell>
                <TableCell>{getFormattedDateTime(localRow.created_at)}</TableCell>
                <TableCell>
                    <StatusBadge status={localRow.status} />
                </TableCell>
                <TableCell>
                    <StatusBadge status={getBlacklistsStatus()} isBlacklistStatus={true} />
                </TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}>
                    <ActionButtons onDelete={handleDelete} onViewScan={handleViewScan} />
                </TableCell>
            </TableRow>
            {expanded && (
                <TableRow
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                        '& > td': {
                            borderBottom: 'none',
                        },
                    }}
                >
                    <TableCell colSpan={6} sx={{ p: 0 }}>
                        <Collapse in={expanded} timeout={500} unmountOnExit>
                            <Box sx={{ py: 2 }}>
                                {isDraft && (
                                    <Box
                                        sx={{
                                            mb: 2,
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            pr: 2,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="error"
                                            startIcon={<SendIcon />}
                                            onClick={handleSendTakedown}
                                            sx={{
                                                fontSize: '0.875rem',
                                                textTransform: 'none',
                                            }}
                                        >
                                            Send Takedown
                                        </Button>
                                    </Box>
                                )}
                                <TakedownExpandedContent
                                    row={localRow}
                                    onAdditionalInfoChange={onAdditionalInfoChange}
                                    onTakedownChange={handleTakedownChange}
                                    isSendingTakedown={isSendingTakedown}
                                />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            {/* Confirmation Dialog */}
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                onClick={(e) => e.stopPropagation()}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirm Takedown Request</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to send this takedown request? This action will initiate takedown procedures with all enabled providers and blacklists.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmSend}
                        variant="contained"
                        color="error"
                        startIcon={<SendIcon />}
                    >
                        Send Takedown
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TakedownRow;
