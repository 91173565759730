import { useEffect, useState } from 'react';
import { Container, Typography, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Pagination, Box, CardHeader, LinearProgress, Card } from '@mui/material';
import MonitorsTable from '../../components/monitor-table';
import DashboardLayout from '../../components/Dashboard/layout';
import { enqueueSnackbar } from 'notistack';
import { deleteMonitor, getAllMonitors } from '../../api';

function MonitorsPage() {
    const [expandedRow, setExpandedRow] = useState(null);

    const [monitors, setMonitors] = useState([]);
    const [loading, setLoading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handleDeleteModal = (monitor) => {
        setOpenDialog(true);
        setPendingDelete({
            monitor_id: monitor.uid,
            analysis_id: monitor.analysis_id,
        });
    };

    useEffect(() => {
        setLoading(true);

        const pageConfig = {
            page: currentPage,
            page_size: 20,
        }

        getAllMonitors(pageConfig)
            .then((response) => {
                setMonitors(response?.data?.results || []);
                setLoading(false);

                const pages = Math.ceil(response.data.count / 20);
                setTotalPages(pages)
            })
            .catch((err) => {
                enqueueSnackbar("Failed to load monitors", {
                    variant: "error",
                });

                setLoading(false);
            });
    }, [currentPage]);


    const handleMonitorDelete = () => {
        const data = { monitor_ids: [pendingDelete.monitor_id] };
        deleteMonitor(pendingDelete.analysis_id, data).then(() => {
            setOpenDialog(false);

            //remove the monitor from the list
            setMonitors((prev) => prev.filter((m) => m.uid !== pendingDelete.monitor_id));
            enqueueSnackbar("Monitor deleted successfully", {
                variant: "success",
            });
        }).catch(() => {
            enqueueSnackbar("Could not delete monitor", {
                variant: "error",
            });
        });
    };

    const handlePageChange = (_, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowClick = (monitorId) => {
        setExpandedRow(expandedRow === monitorId ? null : monitorId);
    };

    return (
        <DashboardLayout>
            <Container maxWidth="100%" sx={{ p: 3 }}>
                <Card sx={{
                    width: '100%',
                    maxWidth: '100%',
                    height: 'calc(100vh - 100px)',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}>
                    <Box>
                        <Paper elevation={3} sx={{ p: 0, mb: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                                <CardHeader title="Monitors" sx={{ p: 0 }} />
                            </Box>
                            <Box>
                                {loading &&
                                    <LinearProgress />
                                }
                            </Box>
                            <Paper elevation={2}>
                                {monitors &&
                                    <MonitorsTable
                                        monitors={monitors}
                                        loading={loading}
                                        expandedRow={expandedRow}
                                        onRowClick={handleRowClick}
                                        onMonitorDelete={handleDeleteModal}
                                    />
                                }
                            </Paper>

                        </Paper>
                    </Box>
                </Card>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                    />
                </Box>
            </Container>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Delete Monitor</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this monitor? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={handleMonitorDelete} color="error">
                        Delete Monitor
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
}

export default MonitorsPage;