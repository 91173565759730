"use client";
import "./widgets.css";

const monitoringFeatures = [
	{
		title: "Website Content Changes and Risk Analysis",
		description:
			"Detects unauthorized modifications, such as fraudulent brand name or logo usage, detects phishing attempts and flags changes in a domain’s risk level.",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="48px"
				viewBox="0 -960 960 960"
				width="48px"
				fill="#2fafed"
			>
				<path d="M796-121 533-384q-30 26-69.96 40.5Q423.08-329 378-329q-108.16 0-183.08-75Q120-479 120-585t75-181q75-75 181.5-75t181 75Q632-691 632-584.85 632-542 618-502q-14 40-42 75l264 262-44 44ZM377-389q81.25 0 138.13-57.5Q572-504 572-585t-56.87-138.5Q458.25-781 377-781q-82.08 0-139.54 57.5Q180-666 180-585t57.46 138.5Q294.92-389 377-389Z" />
			</svg>
		),
	},
	{
		title: "DNS Record Monitoring",
		description:
			"Tracks changes to critical records (NS, A, and MX) that may indicate domain hijacking.",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="48px"
				viewBox="0 -960 960 960"
				width="48px"
				fill="#2fafed"
			>
				<path d="M140-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h240q24 0 42 18t18 42v600q0 24-18 42t-42 18H140Zm440 0q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h240q24 0 42 18t18 42v600q0 24-18 42t-42 18H580Zm-440-60h240v-600H140v600Zm440 0h240v-600H580v600ZM200-367h120v-60H200v60Zm440 0h120v-60H640v60ZM200-491h120v-60H200v60Zm440 0h120v-60H640v60ZM200-615h120v-60H200v60Zm440 0h120v-60H640v60ZM140-180h240-240Zm440 0h240-240Z" />
			</svg>
		),
	},
	{
		title: "WHOIS & Domain Registrations",
		description: "Identifies newly registered domains that mimic your brand.",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="48px"
				viewBox="0 -960 960 960"
				width="48px"
				fill="#2fafed"
			>
				<path d="M624-40q-106 0-186-64.5T335-267q29 0 58.5-4.5T449-285h233.16q1.42-14.17 2.13-27.7t.71-27.7v-24.1q0-12.5-2-24.5h-78q12.28-13.66 22.64-28.83Q638-433 647-449h185q-23-42-60-72t-84-43q2-14 3.5-29.5T693-624q98 23 162.5 102.5T920-337q0 123.75-86.33 210.37Q747.33-40 624-40Zm-79-75q-11-27-19-54t-13-56h-97q21 38 54 66.5t75 43.5Zm79 14q18-29 30-60.5t19-63.5h-99q3 13 18.5 54t31.5 70Zm79-14q42-15 75-43.5t54-66.5h-97q-5 29-13 56t-19 54Zm40-170h111.47q2.53-12 4.03-25 1.5-13 1.5-27.24 0-14.23-1.5-26.52-1.5-12.3-4.5-25.24H743q2 12 2.5 24t.5 25q0 14.09-.5 27.55Q745-299 743-285Zm-405.82-42q-123.83 0-210.5-86.68Q40-500.35 40-624.18 40-748 126.68-834q86.67-86 210.5-86Q461-920 547-834t86 209.82q0 123.83-86 210.5Q461-327 337.18-327Zm-.2-60Q435-387 504-456.48q69-69.47 69-167.5Q573-722 503.81-791 434.62-860 337-860q-98.03 0-167.52 69.19Q100-721.62 100-624q0 98.03 69.48 167.52Q238.95-387 336.98-387ZM234.5-655q12.5 0 21-9t8.5-21.5q0-12.5-8.62-21-8.63-8.5-21.38-8.5-12 0-21 8.62-9 8.63-9 21.38 0 12 9 21t21.5 9ZM337-475q45.6 0 81.23-25.59Q453.85-526.19 470-566H204q16.15 39.81 51.77 65.41Q291.4-475 337-475Zm102.5-180q12.5 0 21-9t8.5-21.5q0-12.5-8.62-21-8.63-8.5-21.38-8.5-12 0-21 8.62-9 8.63-9 21.38 0 12 9 21t21.5 9ZM337-624Z" />
			</svg>
		),
	},
	{
		title: "Infrastructure Monitoring",
		description:
			"Alerts on unauthorized port activity (e.g., HTTP/HTTPS) and phishing database listings.",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="48px"
				viewBox="0 -960 960 960"
				width="48px"
				fill="#2fafed"
			>
				<path d="M236-357h60q20 0 35-15t15-35v-146q0-20-15-35t-35-15h-60q-20 0-35 15t-15 35v146q0 20 15 35t35 15Zm0-50v-146h60v146h-60Zm164 50h110q20 0 35-15t15-35v-146q0-20-15-35t-35-15H400v246Zm50-50v-146h60v146h-60Zm163 50h127q14.03 0 23.51-9.49Q773-375.98 773-390v-76q0-14-9.49-23.5-9.48-9.5-23.51-9.5h-77v-54h110v-50H646q-14.03 0-23.51 9.49Q613-584.03 613-570v78q0 14 9.49 23.5 9.48 9.5 23.51 9.5h77v52H613v50ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z" />
			</svg>
		),
	},
];

function MonitoringFeatures() {
	return (
		<div className="monitoring-container monitoring-css container">
			<div className="monitoring-grid">
				{monitoringFeatures.map((feature, index) => (
					<div key={index} className="feature-card">
						<div className="feature-icon">{feature.icon}</div>
						<h3 className="feature-title">{feature.title}</h3>
						<p className="feature-description">{feature.description}</p>
					</div>
				))}
			</div>
		</div>
	);
}

export default MonitoringFeatures;
