import React, { useEffect, useState } from "react";
import {
	Modal,
	Box,
	Typography,
	Switch,
	Button,
	TextField,
	FormControl,
	Select,
	MenuItem,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	IconButton,
	Divider,
	LinearProgress,
	Tooltip,
	InputLabel,
	Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import {
	getPowerFile,
	getPowerOfAttorney,
	sendTakeDown,
	startTakeDown,
	uploadPowerOfAttorney,
} from "../../api";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
	setSelectedPOA,
	updateAuthLetter,
	updatePOAFile,
	updateSelectedPOA,
} from "../../slices/scan-slice";
import { Info } from "@mui/icons-material";

export default function TakedownModal({
	open,
	onClose,
	domain,
	originalDomain,
	scanData,
}) {
	const [notifyRegistrar, setNotifyRegistrar] = useState(false);
	const [notifyHosting, setNotifyHosting] = useState(false);
	const [selectedBlacklists, setSelectedBlacklists] = useState([]);
	const [additionalNotes, setAdditionalNotes] = useState("");
	const [expanded, setExpanded] = useState(true);
	const [loading, setLoading] = useState(false);
	const [isSendingTakedown, setIsSendingTakeDown] = useState(false);

	const [takedownRequested, setTakedownRequested] = useState(false);
	const [takedownRequestedResponse, setTakeDownRequestedResponse] = useState(
		{}
	);

	const [powerOfAttorney, setPowerOfAttorney] = useState(null);
	const [availablePOAs, setAvailablePOAs] = useState([]);
	const [selectedPOAId, setSelectedPOAId] = useState("");
	const [poaFile, setPoaFile] = useState(null);
	const [poaName, setPoaName] = useState("");
	const [showPOAUploadForm, setShowPOAUploadForm] = useState(false);
	const [poaUploading, setPoaUploading] = useState(false);
	const [takeDownRequested, setTakeDownRequested] = useState(false);

	const { analysis_id: paramAnalysisId } = useParams();
	const effectiveAnalysisId = paramAnalysisId || scanData?.uid;

	const showDetails = takedownRequested;
	const dispatch = useDispatch();

	const handlePOASave = async () => {
		if (!poaFile || !poaName) {
			enqueueSnackbar("Please provide both name and file", {
				variant: "error",
			});
			return;
		}
		setPoaUploading(true);

		try {
			const formData = new FormData();

			if (!(poaFile instanceof File)) {
				throw new Error("Invalid file object");
			}
			formData.append("signed_power_file", poaFile, poaFile.name); // Add filename as third parameter
			formData.append("name", poaName);

			console.log("POA File:", {
				name: poaFile.name,
				type: poaFile.type,
				size: poaFile.size,
			});

			const response = await uploadPowerOfAttorney(formData);
			if (response.data) {
				dispatch(updateSelectedPOA(response.data.id));
				dispatch(updatePOAFile(response.data));
				setSelectedPOAId(response.data.id);
				const poaResponse = await getPowerOfAttorney();
				setAvailablePOAs(poaResponse.data);
				setPoaFile(null);
				setPoaName("");
				enqueueSnackbar("Power of Attorney saved successfully", {
					variant: "success",
				});
			}
		} catch (error) {
			console.error("Upload error details:", {
				response: error.response?.data,
				status: error.response?.status,
			});
			const errorMessage =
				error.response?.data?.signed_power_file?.[0] ||
				error.response?.data?.detail ||
				"Error uploading file";
			enqueueSnackbar(errorMessage, { variant: "error" });
		} finally {
			setPoaUploading(false);
		}
	};

	const handleBlacklistChange = (event) => {
		setSelectedBlacklists(event.target.value);
	};

	const handleSendTakedown = () => {
		setIsSendingTakeDown(true);
		const data = {
			additional_notes: additionalNotes,
		};

		sendTakeDown(takedownRequestedResponse.uid, data)
			.then((response) => {
				enqueueSnackbar("Takedown sent successfully", {
					variant: "success",
				});
				setIsSendingTakeDown(false);

				onClose();
			})
			.catch((error) => {
				if (error.response.status === 400) {
					if (Object.keys(error.response.data).includes("signed_power")) {
						enqueueSnackbar(error.response.data.signed_power, {
							variant: "error",
						});
					} else {
						enqueueSnackbar(error.response.data.details, {
							variant: "warning",
						});
					}
				} else {
					enqueueSnackbar("Takedown was unable to send. An error occured.", {
						variant: "error",
					});
				}
				setIsSendingTakeDown(false);
				onClose();
			});
	};

	const handleRequestTakedown = () => {
		console.log({
			domain,
			notifyRegistrar,
			notifyHosting,
			selectedBlacklists,
			additionalNotes,
			powerOfAttorney,
		});

		setLoading(true);

		const formData = new FormData();
		formData.append("analysis_id", effectiveAnalysisId);
		formData.append("domain", domain);
		formData.append("registrar", notifyRegistrar);
		formData.append("hosting", notifyHosting);

		selectedBlacklists.forEach((blacklist) => {
			formData.append('blacklists', blacklist);
		});

		if (powerOfAttorney || selectedPOAId) {
			formData.append(
				"power_of_attorney_id",
				powerOfAttorney ? powerOfAttorney.id : selectedPOAId
			);
		}

		startTakeDown(formData)
			.then((response) => {
				setTakedownRequested(true);
				setTakeDownRequestedResponse(response.data);
				setLoading(false);
				setTakeDownRequested(true);
			})
			.catch((error) => {
				if (error?.response?.data?.details) {
					enqueueSnackbar(
						error?.response?.data?.details,
						{
							variant: "error",
						}
					);
				} else {
					enqueueSnackbar("An error has occured", {
						variant: "error",
					});
				}


				setLoading(false);
			});
	};

	const handlePOAFileSelect = (e) => {
		const file = e.target.files[0];
		if (file) {
			setPoaFile(file);
		}
	};

	const handlePOASelect = (poaId) => {
		console.log(poaId);

		setSelectedPOAId(poaId);
		const selectedPOA = availablePOAs.find((poa) => poa.id === poaId);
		if (selectedPOA) {
			setPoaFile(selectedPOA);
		}
	};

	useEffect(() => {
		if (scanData) {
			setPowerOfAttorney(scanData.power_of_attorney);
		}
	}, [scanData]);

	useEffect(() => {
		const fetchPOAs = async () => {
			try {
				const response = await getPowerOfAttorney();
				setAvailablePOAs(response.data);
			} catch (error) {
				enqueueSnackbar("Unable to fetch power of attorney documents", {
					variant: "error",
				});
			}
		};
		fetchPOAs();
	}, []);

	return (
		<Modal open={open} onClose={onClose} aria-labelledby="takedown-modal-title">
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "50%",
					maxWidth: "50%",
					bgcolor: "background.paper",
					borderRadius: 1,
					boxShadow: 24,
					maxHeight: "90vh",
					overflow: "auto",
					p: 3,
				}}
			>
				<Typography variant="h6" mb={2} component="h2" gutterBottom>
					Takedown Request
				</Typography>

				<Box
					sx={{
						display: "flex",
						width: "100%",
						gap: 5,
						marginBottom: 3,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography variant="subtitle2">Domain</Typography>
						<Typography>{domain}</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography variant="subtitle2">Notify Registrar</Typography>
						<Switch
							checked={notifyRegistrar}
							onChange={(e) => setNotifyRegistrar(e.target.checked)}
						/>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography variant="subtitle2">Notify Hosting Provider</Typography>
						<Switch
							checked={notifyHosting}
							onChange={(e) => setNotifyHosting(e.target.checked)}
						/>
					</Box>
				</Box>

				<Box
					sx={{
						display: "flex",
						width: "100%",
						marginBottom: 3,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Typography variant="subtitle2">Submit to Blacklist</Typography>
						<FormControl fullWidth size="small">
							<Select
								fullWidth
								multiple
								value={selectedBlacklists}
								onChange={handleBlacklistChange}
								renderValue={(selected) => selected.join(", ")}
								displayEmpty
							>
								<MenuItem disabled value="">
									<em>Optional - Select blacklists</em>
								</MenuItem>
								<MenuItem value="google">Google Safe Browsing</MenuItem>
								<MenuItem value="microsoft">Microsoft Security Intelligence</MenuItem>
								<MenuItem value="spamhaus">Spamhaus</MenuItem>
								<MenuItem value="openphish">OpenPhish</MenuItem>
								<MenuItem value="netcraft">Netcraft</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Box>

				{!takeDownRequested && (
					<Box
						sx={{
							display: "flex",
							width: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							{loading || (!powerOfAttorney && !selectedPOAId) ? (
								<Tooltip title="Please choose or upload a power of attorney file">
									<span>
										<Button
											variant="contained"
											onClick={handleRequestTakedown}
											disabled
											sx={{
												bgcolor: "#CD6B6B",
												"&:hover": { bgcolor: "#B25757" },
												color: "white",
												height: "54px",
												width: "100%",
											}}
										>
											REQUEST TAKE-DOWN
										</Button>
									</span>
								</Tooltip>
							) : (
								<Button
									variant="contained"
									onClick={handleRequestTakedown}
									disabled={false}
									sx={{
										bgcolor: "#CD6B6B",
										"&:hover": { bgcolor: "#B25757" },
										color: "white",
										height: "54px",
										width: "100%",
									}}
								>
									REQUEST TAKE-DOWN
								</Button>
							)}
						</Box>
					</Box>
				)}

				{loading && (
					<>
						<LinearProgress />
						<Typography variant="body2" textAlign={"center"} mt={2}>
							Please wait while your takedown is being processed
						</Typography>
					</>
				)}

				{!powerOfAttorney && (
					<>
						<Divider sx={{ my: 3 }} />

						<Box
							fullWidth
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								alignItems: "center",
								mb: 3,
								width: "100%",
							}}
						>
							<Box sx={{ display: "flex", width: "100%", gap: 2 }}>
								<Box
									sx={{ display: "flex", alignItems: "center", width: "50%" }}
								>
									<Tooltip
										title="Upload a signed Power of Attorney document that authorizes SpoofGuard to send takedown requests on behalf your organization."
										arrow
									>
										<IconButton size="small" sx={{ mr: 1 }}>
											<Info fontSize="small" />
										</IconButton>
									</Tooltip>
									<FormControl fullWidth>
										<InputLabel>Select Power of Attorney</InputLabel>
										<Select
											value={selectedPOAId}
											onChange={(e) => handlePOASelect(e.target.value)}
											label="Select Power of Attorney"
											disabled={!availablePOAs.length}
										>
											{availablePOAs.map((poa) => (
												<MenuItem key={poa.id} value={poa.id}>
													{poa.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>

								<Box
									sx={{ display: "flex", alignItems: "center", width: "50%" }}
								>
									<Button
										variant="contained"
										onClick={() => setShowPOAUploadForm(true)}
										sx={{ minWidth: "100%", height: "54px" }}
									>
										Upload Power of Attorney
									</Button>
								</Box>
							</Box>

							{showPOAUploadForm && (
								<Paper sx={{ mt: 2, p: 2, width: "100%" }}>
									<Box
										sx={{ display: "flex", flexDirection: "column", gap: 2 }}
									>
										<TextField
											fullWidth
											label="Power of Attorney Name"
											value={poaName}
											onChange={(e) => setPoaName(e.target.value)}
										/>
										<Box sx={{ display: "flex", gap: 2 }}>
											<Button
												variant="outlined"
												component="label"
												startIcon={<CloudUploadIcon />}
											>
												{poaUploading ? "Uploading..." : "Choose File"}

												<input
													type="file"
													hidden
													accept=".pdf,.doc,.docx,.odt"
													onChange={handlePOAFileSelect}
												/>
											</Button>
											<Typography
												variant="body2"
												color="primary"
												sx={{
													cursor: "pointer",
													"&:hover": {
														textDecoration: "underline",
													},
													width: "fit-content",
													display: "flex",
													alignItems: "center",
												}}
												onClick={() => {
													getPowerFile()
														.then((response) => {
															const url = window.URL.createObjectURL(
																new Blob([response.data])
															);
															const link = document.createElement("a");
															link.href = url;
															link.setAttribute(
																"download",
																"authorization_letter.odt"
															);
															document.body.appendChild(link);
															link.click();

															enqueueSnackbar("Powerfile downloaded", {
																variant: "success",
															});
														})
														.catch(() => {
															enqueueSnackbar("Unable to download powerfile", {
																variant: "error",
															});
														});
												}}
											>
												Download sample file
											</Typography>
										</Box>
										{poaFile && (
											<Typography
												variant="body2"
												sx={{ alignSelf: "flex-start" }}
											>
												{poaFile.name}
											</Typography>
										)}
										<Box
											sx={{
												display: "flex",
												gap: 2,
												justifyContent: "flex-end",
											}}
										>
											<Button
												onClick={() => {
													setShowPOAUploadForm(false);
													setPoaFile(null);
													setPoaName("");
												}}
											>
												Cancel
											</Button>
											<Button
												variant="contained"
												onClick={handlePOASave}
												disabled={!poaFile || !poaName}
											>
												Save
											</Button>
										</Box>
									</Box>
								</Paper>
							)}

							{/* <Button
                                variant="outlined"
                                component="label"
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload Power of Attorney
                                <input
                                    type="file"
                                    hidden
                                    onChange={handlePowerOfAttorneyUpload}
                                />
                            </Button>
                            <Button
                                variant="text"
                                startIcon={<DownloadIcon />}
                                onClick={handleDownloadSample}
                            >
                                Download Sample File
                            </Button> */}
						</Box>

						{powerOfAttorney && (
							<Typography variant="body2" sx={{ mb: 3 }}>
								Uploaded: {powerOfAttorney.name}
							</Typography>
						)}
					</>
				)}

				{showDetails && (
					<Accordion
						expanded={expanded}
						onChange={() => setExpanded(!expanded)}
						sx={{
							"&:before": { display: "none" },
							boxShadow: "none",
							border: "1px solid rgba(0, 0, 0, 0.12)",
							mt: 2,
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							sx={{ bgcolor: "background.paper" }}
						>
							<Typography>Response Details</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
								<Box>
									<Typography variant="subtitle1" gutterBottom>
										Domain Data
									</Typography>
									<Typography variant="body2" sx={{ mb: 1 }}>
										<strong>Malicious Domain:</strong> {domain}
									</Typography>
									<Typography variant="body2">
										<strong>Original Domain:</strong> {originalDomain}
									</Typography>
								</Box>

								<Box sx={{ display: "flex", gap: 2 }}>
									<Box sx={{ flex: 1 }}>
										<Typography variant="subtitle1" gutterBottom>
											Original Website
										</Typography>
										{takedownRequestedResponse.original_screenshot_link ? (
											<Box
												sx={{
													width: "100%",
													height: 200,
													bgcolor: "rgba(0, 0, 0, 0.04)",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 1,
													overflow: "auto",
												}}
											>
												<img
													alt="Original Website"
													src={
														takedownRequestedResponse.original_screenshot_link
													}
												/>
											</Box>
										) : (
											<Box
												sx={{
													width: "100%",
													height: 200,
													bgcolor: "rgba(0, 0, 0, 0.04)",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 1,
												}}
											>
												<Typography color="text.secondary">
													Original Website Screenshot
												</Typography>
											</Box>
										)}
									</Box>
									<Box sx={{ flex: 1 }}>
										<Typography variant="subtitle1" gutterBottom>
											Malicious Website
										</Typography>
										{takedownRequestedResponse.malicious_screenshot_link ? (
											<Box
												sx={{
													width: "100%",
													height: 200,
													bgcolor: "rgba(0, 0, 0, 0.04)",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 1,
													overflow: "auto",
												}}
											>
												<img
													alt="Malicious Website"
													src={
														takedownRequestedResponse.malicious_screenshot_link
													}
												/>
											</Box>
										) : (
											<Box
												sx={{
													width: "100%",
													height: 200,
													bgcolor: "rgba(0, 0, 0, 0.04)",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 1,
												}}
											>
												<Typography color="text.secondary">
													Original Website Screenshot
												</Typography>
											</Box>
										)}
									</Box>
								</Box>

								<Box>
									<Typography variant="subtitle1" gutterBottom>
										Additional Notes
									</Typography>
									<TextField
										fullWidth
										multiline
										rows={4}
										placeholder="Enter additional notes..."
										value={additionalNotes}
										onChange={(e) => setAdditionalNotes(e.target.value)}
									/>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									mt: 3,
									mb: 3,
								}}
							>
								<Button
									variant="contained"
									onClick={handleSendTakedown}
									sx={{
										bgcolor: "#CD6B6B",
										"&:hover": { bgcolor: "#B25757" },
										color: "white",
									}}
								>
									SEND TAKEDOWN
								</Button>
							</Box>
							{isSendingTakedown && <LinearProgress />}
						</AccordionDetails>
					</Accordion>
				)}
			</Box>
		</Modal>
	);
}
