import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { resetpassword } from "../actions/accountActions";

import {
	Box,
	Container,
	TextField,
	Button,
	Typography,
	InputAdornment,
} from "@mui/material";

const ResetPassword = () => {
	const { token } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [formData, setFormData] = useState({
		password: "",
		password_confirm: "",
		token: token,
	});

	const [errors, setErrors] = useState({
		password: "",
		password_confirm: "",
	});

	const validateField = (name, value) => {
		let error = "";
		if (!value.trim()) {
			error = `Field is required`;
			return error;
		}
		switch (name) {
			case "password":
				if (value.length < 8) {
					error = "Password must be at least 8 characters long";
				} else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value)) {
					error =
						"Password must contain at least one uppercase letter, one lowercase letter, and one number";
				}
				break;
			case "password_confirm":
				if (value !== formData.password) {
					error = "Passwords do not match";
				}
				break;
			default:
				break;
		}
		return error;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: validateField(name, value),
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const newErrors = {};
		let isValid = true;

		Object.keys(formData).forEach((key) => {
			const error = validateField(key, formData[key]);
			newErrors[key] = error;
			if (error) {
				isValid = false;
			}
		});

		setErrors(newErrors);

		if (isValid) {
			try {
				await dispatch(resetpassword(formData));
				navigate("/login");
			} catch (error) {
				const message = error.response.data.detail;
				enqueueSnackbar(message, {
					variant: "error",
				});
			}
		} else {
			console.log("Form has errors, please correct them");
		}
	};

	return (
		<Container
			disableGutters
			maxWidth={false}
			sx={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundImage:
					"url(/assets/images/account/SG_login_man_at_desk.webp)",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundSize: "cover",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					backgroundColor: "white",
					padding: 4,
					borderRadius: "4px",
					boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
					width: "600px",
				}}
			>
				<Typography
					component="h1"
					variant="h3"
					sx={{
						mb: 1,
						fontWeight: "bold",
					}}
				>
					Reset Password?
				</Typography>
				<Typography
					variant="h6"
					sx={{
						mb: 4,
						color: "text.secondary",
					}}
				>
					Please enter new password and confirm password
				</Typography>

				<Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
					<TextField
						fullWidth
						name="password"
						type="password"
						placeholder="Password *"
						value={formData.password}
						onChange={handleChange}
						error={!!errors.password}
						helperText={errors.password}
						InputProps={{
							endAdornment: <InputAdornment position="end">🔒</InputAdornment>,
						}}
						sx={{
							"& .MuiOutlinedInput-root": { borderRadius: "4px" },
							marginBottom: "15px",
						}}
						required
					/>
					<TextField
						fullWidth
						name="password_confirm"
						type="password"
						placeholder="Confirm Password *"
						value={formData.password_confirm}
						onChange={handleChange}
						error={!!errors.password_confirm}
						helperText={errors.password_confirm}
						InputProps={{
							endAdornment: <InputAdornment position="end">🔒</InputAdornment>,
						}}
						sx={{ "& .MuiOutlinedInput-root": { borderRadius: "4px" } }}
						required
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							borderRadius: "4px",
							padding: "12px",
							textTransform: "none",
							fontSize: "1.1rem",
							backgroundColor: "#2196f3",
							"&:hover": {
								backgroundColor: "#1976d2",
							},
						}}
					>
						Reset Password
					</Button>
				</Box>
			</Box>
		</Container>
	);
};

export default ResetPassword;
