import React from 'react';
import { IconButton, Tooltip, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const ActionButtons = ({ onDelete, onViewScan }) => {
    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Go back to Scan">
                <IconButton
                    size="small"
                    onClick={onViewScan}
                    sx={{ color: 'text.secondary' }}
                >
                    <ExitToAppIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <IconButton
                size="small"
                onClick={onDelete}
                sx={{ color: 'text.secondary' }}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Box>
    );
};

export default ActionButtons; 