import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./components/layout/layout.js";
import Dashboard from "./pages/Dashboard/dashboard.js";
import Reports from "./pages/Dashboard/reports.js";
import Settings from "./pages/Dashboard/settings.js";
import Auth from "./components/Auth.js";
import AuthGuard from "./components/AuthGuard.js";
import GuestGuard from "./components/GuestGuard.js";
import AboutUs from "./pages/aboutus.js";
import Accountactivate from "./pages/accountactivate.js";
import Forgotpassword from "./pages/forgotpassword.js";
import Home from "./pages/home.js";
import Login from "./pages/login.js";
import NotFound from "./pages/notfound.js";
import Register from "./pages/register.js";
import ResetPassword from "./pages/resetpassword.js";
import Technology from "./pages/technology.js";

import "./App.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Success from "./pages/Success.js";
import Monitoring from "./pages/Dashboard/monitors.js";
import Alerts from "./pages/Dashboard/alerts.js";
import NewRecentScans from "./pages/Dashboard/new-scan/recent-scans.js";
import DomainAnalysis from "./pages/Dashboard/new-scan/domain-analysis.js";
import TakeDown from "./pages/Dashboard/takedown_new.js";
const history = createBrowserHistory();
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API}`);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <SnackbarProvider maxSnack={1}>
        <Auth>
          <BrowserRouter history={history}>
            <Routes>
              <Route
                path="/"
                element={
                  <Layout>
                    <Home />
                  </Layout>
                }
              />           
              <Route
                path="/aboutus"
                element={
                  <Layout>
                    <AboutUs />
                  </Layout>
                }
              />
              <Route
                path="/technology"
                element={
                  <Layout>
                    <Technology />
                  </Layout>
                }
              />
              {/* <Route
                path="/pricing"
                element={
                  <Layout>
                    <Pricing />
                  </Layout>
                }
              /> */}
              <Route
                path="/login"
                element={
                  <GuestGuard>
                    <Login />
                  </GuestGuard>
                }
              />
              <Route
                path="/register"
                element={
                  <GuestGuard>
                    <Register />
                  </GuestGuard>
                }
              />
              <Route path="/forgotpassword" element={<Forgotpassword />} />
              <Route
                path="/password-reset/token/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/dashboard/index"
                element={
                  <AuthGuard>
                    <Dashboard />
                  </AuthGuard>
                }
              />
              <Route
                path="/dashboard/scan"
                element={
                  <AuthGuard>
                    <NewRecentScans />
                  </AuthGuard>
                }
              />

              <Route
                path="/dashboard/domain-analysis/:analysis_id"
                element={
                  <AuthGuard>
                    <DomainAnalysis />
                  </AuthGuard>
                }
              />         
              <Route
                path="/dashboard/settings"
                element={
                  <AuthGuard>
                    <Settings />
                  </AuthGuard>
                }
              />
               <Route
                path="/dashboard/takedowns"
                element={
                  <AuthGuard>
                    <TakeDown />
                  </AuthGuard>
                }
              />
              <Route
                path="/dashboard/alerts"
                element={
                  <AuthGuard>
                    <Alerts />
                  </AuthGuard>
                }
              />
              <Route
                path="/dashboard/monitors"
                element={
                  <AuthGuard>
                    <Monitoring />
                  </AuthGuard>
                }
              />
              <Route
                path="/dashboard/reports"
                element={
                  <AuthGuard>
                    <Reports />
                  </AuthGuard>
                }
              />      
              <Route
                path="/success"
                element={
                  <AuthGuard>
                    <Success />
                  </AuthGuard>
                }
              />
              <Route
                path="/activate-account/:id/:token"
                element={<Accountactivate />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Auth>
      </SnackbarProvider>
    </Elements>
  );
}

export default App;
