import React, { useState, useMemo, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Chip,
    TablePagination,
    TableSortLabel,
    TextField,
    Box,
    IconButton,
    Select,
    MenuItem,
    Collapse,
    Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AlertsTable = ({ alerts, onDeleteAlert, preselectFilter, condensed = false }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [orderBy, setOrderBy] = useState('created_at');
    const [order, setOrder] = useState('desc');
    const navigate = useNavigate();
    const location = useLocation();

    const alertConditions = useSelector(state => state.scan.config.alert_conditions)

    const [filters, setFilters] = useState({
        domain: location.state?.filterDomain || "",
        alertType: preselectFilter ? preselectFilter : "",
        isSeen: ""
    });

    useEffect(() => {
        if (location.state?.filterDomain) {
            setFilters(prev => ({
                ...prev,
                domain: location.state.filterDomain
            }));
        }
    }, [location.state?.filterDomain]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const handleViewScan = (alert) => {
        navigate(`/dashboard/domain-analysis/${alert.analysis_id}`, {
            state: {
                filteredDomain: alert.domain
            }
        });
    };

    const filteredAlerts = useMemo(() => {
        return alerts.filter((alert) => {
            const matchesDomain = alert.domain.toLowerCase().includes(filters.domain.toLowerCase());
            const matchesAlertType =
                filters.alertType === "" ||
                alertConditions?.some(
                    (condition) => condition.code === alert.condition && condition.code === filters.alertType
                );
            const matchesSeenStatus =
                filters.isSeen === "" ||
                (filters.isSeen === "seen" && alert.seen) ||
                (filters.isSeen === "sent" && !alert.seen);

            return matchesDomain && matchesAlertType && matchesSeenStatus;
        });
    }, [alerts, filters, alertConditions]);

    const sortedAlerts = useMemo(() => {
        const comparator = (a, b) => {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        };
        return filteredAlerts.sort((a, b) => {
            return order === 'desc' ? comparator(a, b) : -comparator(a, b);
        });
    }, [filteredAlerts, order, orderBy]);

    return (
        <>

            <TableContainer sx={{ maxHeight: 'calc(100vh - 100px)', boxShadow: 'none' }}>
                <Table stickyHeader aria-label="alerts table">
                    <TableHead>
                        <TableRow>
                            {!condensed &&
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'domain'}
                                        direction={orderBy === 'domain' ? order : 'asc'}
                                        onClick={() => handleRequestSort('domain')}
                                    >
                                        Domain
                                    </TableSortLabel>
                                </TableCell>

                            }
                            <TableCell>Message</TableCell>
                            {!condensed &&
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'alertType'}
                                        direction={orderBy === 'alertType' ? order : 'asc'}
                                        onClick={() => handleRequestSort('alertType')}
                                    >
                                        Alert Type
                                    </TableSortLabel>
                                </TableCell>
                            }
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'created_at'}
                                    direction={orderBy === 'created_at' ? order : 'asc'}
                                    onClick={() => handleRequestSort('created_at')}
                                >
                                    Created At
                                </TableSortLabel>
                            </TableCell>
                            {!condensed &&
                                <TableCell>Status</TableCell>
                            }
                            {/*
                            <TableCell>Actions</TableCell>
                             */}
                        </TableRow>
                        <TableRow>
                            {!condensed &&
                                <TableCell style={{ minWidth: "140px" }}>
                                    <TextField
                                        variant="standard"
                                        placeholder="Filter by Domain"
                                        value={filters.domain}
                                        onChange={(e) => handleFilterChange("domain", e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                            }
                            <TableCell></TableCell>
                            {!condensed &&
                                <TableCell>
                                    <Select
                                        variant="standard"
                                        value={filters.alertType}
                                        onChange={(e) => handleFilterChange("alertType", e.target.value)}
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {alertConditions?.map((condition) => (
                                            <MenuItem key={condition.code} value={condition.code}>
                                                {condition.name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </TableCell>
                            }
                            <TableCell></TableCell>
                            <TableCell>
                                {!condensed &&
                                    <Select
                                        variant="standard"
                                        value={filters.isSeen}
                                        onChange={(e) => handleFilterChange("isSeen", e.target.value)} // Correct field name
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        <MenuItem value="seen">Seen</MenuItem>
                                        <MenuItem value="sent">Sent</MenuItem>
                                    </Select>
                                }
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedAlerts
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((alert) => (
                                <TableRow
                                    key={alert.uid}
                                    hover
                                    onClick={() => handleViewScan(alert)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {!condensed && (
                                        <TableCell>
                                            {alert.domain}
                                        </TableCell>
                                    )}
                                    <TableCell>{alert.message}</TableCell>
                                    {!condensed &&
                                        <TableCell>{alertConditions?.filter(x => x.code === alert.condition)[0].name}</TableCell>
                                    }
                                    <TableCell>{formatDate(alert.created_at)}</TableCell>
                                    {!condensed &&
                                        <TableCell>
                                            {alert.seen ? "Seen" : "Sent"}
                                        </TableCell>
                                    }
                                    {/**
                                    <TableCell>
                                        <IconButton 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteAlert(alert.uid);
                                            }} 
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                     */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default AlertsTable;