import React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { sendContact } from "../api";
//import ScrollAnimation from "react-animate-on-scroll";

//assets
import placeholder from "./../assets/images/aboutus.png";
import mapImage from "./../assets/images/map-shot.webp";
import "animate.css/animate.compat.css";

/**
 * Google map use once api key sorted
 * Remove the pic above and keep the below	
 * ** then theres the useeffect code in the top of this file and last the import above that by imports		
import React, { useState, useEffect } from "react";

*/

const AboutUs = () => {
	// Contact form state
	const [contactState, setContactState] = useState({
		email: "",
		name: "",
		subject: "Spoofguard contact form submission",
		text: "",
	});

	const { enqueueSnackbar } = useSnackbar();

	// Handle input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setContactState((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// Handle form submission
	const submitContact = (e) => {
		e.preventDefault();

		const formData = new FormData();
		Object.keys(contactState).forEach((key) => {
			formData.append(key, contactState[key]);
		});

		sendContact(formData)
			.then((res) => {
				enqueueSnackbar("Contact form submitted.", {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar("Could not send the form.", {
					variant: "error",
				});
			});
	};

	/**
 * Google map use once api key sorted
 * Remove the pic above and keep the below	
 * ** then theres the useeffect code in the top of this file and last the import above that by imports		
useEffect(() => {
const script = document.createElement("script");
script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap`;
script.async = true;
script.defer = true;
document.body.appendChild(script);

window.initMap = function () {
new window.google.maps.Map(document.getElementById("map"), {
center: { lat: 47.18328257239447, lng: 8.481725477022701 },
zoom: 15,
});
};

return () => {
document.body.removeChild(script);
};
}, []);
*/

	return (
		<div>
			{/* New - orig is below until removed About Us Hero */}
			<section className="hero-techBG security-question ff-page-intro-content">
				<div className="clearfix section-breaks smaller-breaks"></div>
				<div className="container">
					<div className="row">
						<div className="hero-content-techBG ff-page-intro-hero-content">
							<div className="col-lg-12 col-md-12 col-sm-12">
								<h1
									class="text-left custom-line-position"
									style={{
										color: "rgb(0, 0, 0)",
									}}
								>
									Our Vision
								</h1>
								<p className="hero-p">
									We believe domain spoofing protection should be
									straightforward, accessible, and highly effective. By
									outperforming traditional providers at a lower cost, we put
									advanced detection within reach for businesses of every size.
								</p>
								<p>
									<span>
										Spoofguard automates the hard work. We scan for brand and
										logo spoofing, check WHOIS details, look for SMTP servers,
										and monitor web ports. When threats appear, we quickly move
										to takedown. Every function is available through our REST
										API, making it easy for MSSPs and cybersecurity vendors to
										integrate and extend their capabilities.
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="clearfix section-breaks"></div>

			<section className="section-padding technology ff-page-second-section pb-0">
				<div className="why content-template-1 round-box-lift round-box-border-bottom">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-8 col-md-7 col-sm-12 align-content-md-center align-content-sm-end text-left">
								<h1>About Us</h1>
								<p>
									Spoofguard is built for an AI-driven world. Our solution spots
									malicious domains in record time, analyzes them automatically,
									and initiates takedowns so you can stay ahead of phishing
									attacks. But we’re more than just detection. Spoofguard
									delivers proactive protection that stops threats at the
									source. We’re a Europe-based team focused on product
									innovation over unnecessary overhead. Our founder, a
									penetration tester since 2000, brings real-world expertise to
									every line of code, so you get a platform that’s both powerful
									and reliable.
								</p>
								<div className="buttons custom-layout-btns">
									<a
										href="https://calendly.com/spoofguard"
										className="demo-button"
										target="_blank"
										rel="noreferrer"
									>
										Learn More
									</a>
									<a
										href="/register"
										className="demo-button second-type-button"
									>
										Free Trial
									</a>
								</div>
							</div>
							<div className="col-lg-4 col-md-5 col-sm-12">
								<picture>
									<img
										className="placeholdr"
										src={placeholder}
										alt=""
										style={{ maxHeight: "380px" }}
									/>
								</picture>
							</div>
						</div>
					</div>
				</div>
				<section className="security-question ff-page-intro-content">
					<div className="clearfix section-breaks smaller-breaks"></div>
					<div className="container">
						<div className="row">
							<div className="hero-content-techBG ff-page-intro-hero-content text-center">
								<div className="col-lg-12 col-md-12 col-sm-12">
									<h1 className="text-left custom-line-position">
										Key <strong>Milestones</strong> and Achievements
									</h1>
									<p className="text-left margin-auto-use d-none"></p>
									<p className="text-left">
										<span>
											SpoofGuard has been at the forefront of domain monitoring
											since its inception. Our commitment to security has driven
											us to achieve significant milestones in the industry.
										</span>
									</p>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12">
									<div className="timeline">
										<div className="timeline-item">
											<div className="timeline-date">July 2024</div>
											<div className="timeline-dot">&#8226;</div>
											<div className="timeline-content">
												SpoofGuard debuted in July 2024, featuring REST API
												endpoints designed for beta testers to integrate with
												their existing infrastructure.
											</div>
										</div>
										<div className="timeline-item">
											<div className="timeline-date">December 2024</div>
											<div className="timeline-dot">&#8226;</div>
											<div className="timeline-content">
												In December 2024, SpoofGuard introduced the first
												version of its SaaS dashboard, offering beta testers
												options for scanning, monitoring, and takedown
												operations.
											</div>
										</div>
										<div className="timeline-item">
											<div className="timeline-date">February 2025</div>
											<div className="timeline-dot">&#8226;</div>
											<div className="timeline-content">
												This year, we’re thrilled to roll out enhanced
												monitoring capabilities, improved multi-tenancy setups
												for managed service providers, and continuous updates to
												our domain scanning modules. Stay tuned as we push the
												boundaries of innovation to keep your domains secure.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="request-part">
					<section className="hero d-flex">
						<div className="hero-content align-content-xl-center align-content-xs-center">
							<h1 className="center-text text-center">
								Request Your Free Demo Today
							</h1>
							<p>
								Discover how SpoofGuard can protect your online presence. Sign
								up for a demo now!
							</p>
							<form>
								<a
									className="demo-button"
									href="https://calendly.com/spoofguard"
									target="_blank"
									rel="noreferrer"
								>
									Book a Demo
								</a>
							</form>
						</div>
					</section>
				</div>
				{/* Team Section - commented out for now */}
				{/* 
				<div className="team-section">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h2>Our Team</h2>
								<p>Meet the dedicated professionals behind SpoofGuard.</p>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<div className="profile-pic">
									<picture>
										<img
											src="https://media.licdn.com/dms/image/v2/D4D03AQEso_j_v9h_Qw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1716059708845?e=1743638400&v=beta&t=hWbEL_Oegh8RmWNWL7rf5qS92AMTQzQNV27TIB1QZzI"
											alt="Oliver"
											className="pp-class"
										></img>
									</picture>
								</div>
								<div className="profile-details">
									<div className="profile-name">Oliver</div>
									<div className="profile-position">CEO & Founder</div>
									<div className="profile-description">
										Driven by a passion for cybersecurity, Oliver, our founder
										and expert penetration tester, spearheads our mission to
										safeguard digital identities.
									</div>
									<div className="profile-socials">
										<a
											href="https://www.linkedin.com/in/om-switzerland/"
											target="_blank"
											rel="noreferrer"
										>
											<i className="linkedin-ico">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M4.5 3.24268C3.67157 3.24268 3 3.91425 3 4.74268V19.7427C3 20.5711 3.67157 21.2427 4.5 21.2427H19.5C20.3284 21.2427 21 20.5711 21 19.7427V4.74268C21 3.91425 20.3284 3.24268 19.5 3.24268H4.5ZM8.52076 7.2454C8.52639 8.20165 7.81061 8.79087 6.96123 8.78665C6.16107 8.78243 5.46357 8.1454 5.46779 7.24681C5.47201 6.40165 6.13998 5.72243 7.00764 5.74212C7.88795 5.76181 8.52639 6.40728 8.52076 7.2454ZM12.2797 10.0044H9.75971H9.7583V18.5643H12.4217V18.3646C12.4217 17.9847 12.4214 17.6047 12.4211 17.2246C12.4203 16.2108 12.4194 15.1959 12.4246 14.1824C12.426 13.9363 12.4372 13.6804 12.5005 13.4455C12.7381 12.568 13.5271 12.0013 14.4074 12.1406C14.9727 12.2291 15.3467 12.5568 15.5042 13.0898C15.6013 13.423 15.6449 13.7816 15.6491 14.129C15.6605 15.1766 15.6589 16.2242 15.6573 17.2719C15.6567 17.6417 15.6561 18.0117 15.6561 18.3815V18.5629H18.328V18.3576C18.328 17.9056 18.3278 17.4537 18.3275 17.0018C18.327 15.8723 18.3264 14.7428 18.3294 13.6129C18.3308 13.1024 18.276 12.599 18.1508 12.1054C17.9638 11.3713 17.5771 10.7638 16.9485 10.3251C16.5027 10.0129 16.0133 9.81178 15.4663 9.78928C15.404 9.78669 15.3412 9.7833 15.2781 9.77989C14.9984 9.76477 14.7141 9.74941 14.4467 9.80334C13.6817 9.95662 13.0096 10.3068 12.5019 10.9241C12.4429 10.9949 12.3852 11.0668 12.2991 11.1741L12.2797 11.1984V10.0044ZM5.68164 18.5671H8.33242V10.01H5.68164V18.5671Z"
														fill="black"
													/>
												</svg>
											</i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<div className="profile-pic">
									<picture>
										<img
											src="https://media.licdn.com/dms/image/v2/D4D03AQFq5VsB_c4Zyw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1708175871187?e=1743638400&v=beta&t=h8nEAIz65pceGmV15EmZxFBT5OeITPsCveezot5EF88"
											alt="Oliver Muenchow"
											className="pp-class"
										></img>
									</picture>
								</div>
								<div className="profile-details">
									<div className="profile-name">Nick</div>
									<div className="profile-position">Head of Product</div>
									<div className="profile-description">
										As Head of Product, Nick spearheads our technology
										innovations, delivering exceptional security solutions that
										meet and exceed industry standards.
									</div>
									<div className="profile-socials">
										<a
											href="https://www.linkedin.com/in/nick-becker-cybersecurity/"
											target="_blank"
											rel="noreferrer"
										>
											<i className="linkedin-ico">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M4.5 3.24268C3.67157 3.24268 3 3.91425 3 4.74268V19.7427C3 20.5711 3.67157 21.2427 4.5 21.2427H19.5C20.3284 21.2427 21 20.5711 21 19.7427V4.74268C21 3.91425 20.3284 3.24268 19.5 3.24268H4.5ZM8.52076 7.2454C8.52639 8.20165 7.81061 8.79087 6.96123 8.78665C6.16107 8.78243 5.46357 8.1454 5.46779 7.24681C5.47201 6.40165 6.13998 5.72243 7.00764 5.74212C7.88795 5.76181 8.52639 6.40728 8.52076 7.2454ZM12.2797 10.0044H9.75971H9.7583V18.5643H12.4217V18.3646C12.4217 17.9847 12.4214 17.6047 12.4211 17.2246C12.4203 16.2108 12.4194 15.1959 12.4246 14.1824C12.426 13.9363 12.4372 13.6804 12.5005 13.4455C12.7381 12.568 13.5271 12.0013 14.4074 12.1406C14.9727 12.2291 15.3467 12.5568 15.5042 13.0898C15.6013 13.423 15.6449 13.7816 15.6491 14.129C15.6605 15.1766 15.6589 16.2242 15.6573 17.2719C15.6567 17.6417 15.6561 18.0117 15.6561 18.3815V18.5629H18.328V18.3576C18.328 17.9056 18.3278 17.4537 18.3275 17.0018C18.327 15.8723 18.3264 14.7428 18.3294 13.6129C18.3308 13.1024 18.276 12.599 18.1508 12.1054C17.9638 11.3713 17.5771 10.7638 16.9485 10.3251C16.5027 10.0129 16.0133 9.81178 15.4663 9.78928C15.404 9.78669 15.3412 9.7833 15.2781 9.77989C14.9984 9.76477 14.7141 9.74941 14.4467 9.80334C13.6817 9.95662 13.0096 10.3068 12.5019 10.9241C12.4429 10.9949 12.3852 11.0668 12.2991 11.1741L12.2797 11.1984V10.0044ZM5.68164 18.5671H8.33242V10.01H5.68164V18.5671Z"
														fill="black"
													/>
												</svg>
											</i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<div className="profile-pic">
									<picture>
										<img
											src="https://media.licdn.com/dms/image/v2/D4D03AQGKr_wLf5QRsw/profile-displayphoto-shrink_400_400/B4DZSUTM3WHkAg-/0/1737654849905?e=1743638400&v=beta&t=jBOluCzxDm-3GGFIXbpH4w6jsCvnJeFvDYqlQk4xIvs"
											alt="Alejandra P. Vazquez"
											className="pp-class"
										></img>
									</picture>
								</div>
								<div className="profile-details">
									<div className="profile-name">Alejandra</div>
									<div className="profile-position">Head of Sales</div>
									<div className="profile-description">
										Alejandra shapes our brand narrative, fostering connections
										with users through captivating marketing campaigns.
									</div>
									<div className="profile-socials">
										<a
											href="https://www.linkedin.com/in/alejandra-kaduu-cti/"
											target="_blank"
											rel="noreferrer"
										>
											<i className="linkedin-ico">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M4.5 3.24268C3.67157 3.24268 3 3.91425 3 4.74268V19.7427C3 20.5711 3.67157 21.2427 4.5 21.2427H19.5C20.3284 21.2427 21 20.5711 21 19.7427V4.74268C21 3.91425 20.3284 3.24268 19.5 3.24268H4.5ZM8.52076 7.2454C8.52639 8.20165 7.81061 8.79087 6.96123 8.78665C6.16107 8.78243 5.46357 8.1454 5.46779 7.24681C5.47201 6.40165 6.13998 5.72243 7.00764 5.74212C7.88795 5.76181 8.52639 6.40728 8.52076 7.2454ZM12.2797 10.0044H9.75971H9.7583V18.5643H12.4217V18.3646C12.4217 17.9847 12.4214 17.6047 12.4211 17.2246C12.4203 16.2108 12.4194 15.1959 12.4246 14.1824C12.426 13.9363 12.4372 13.6804 12.5005 13.4455C12.7381 12.568 13.5271 12.0013 14.4074 12.1406C14.9727 12.2291 15.3467 12.5568 15.5042 13.0898C15.6013 13.423 15.6449 13.7816 15.6491 14.129C15.6605 15.1766 15.6589 16.2242 15.6573 17.2719C15.6567 17.6417 15.6561 18.0117 15.6561 18.3815V18.5629H18.328V18.3576C18.328 17.9056 18.3278 17.4537 18.3275 17.0018C18.327 15.8723 18.3264 14.7428 18.3294 13.6129C18.3308 13.1024 18.276 12.599 18.1508 12.1054C17.9638 11.3713 17.5771 10.7638 16.9485 10.3251C16.5027 10.0129 16.0133 9.81178 15.4663 9.78928C15.404 9.78669 15.3412 9.7833 15.2781 9.77989C14.9984 9.76477 14.7141 9.74941 14.4467 9.80334C13.6817 9.95662 13.0096 10.3068 12.5019 10.9241C12.4429 10.9949 12.3852 11.0668 12.2991 11.1741L12.2797 11.1984V10.0044ZM5.68164 18.5671H8.33242V10.01H5.68164V18.5671Z"
														fill="black"
													/>
												</svg>
											</i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<div className="profile-pic">
									<picture>
										<img
											src="https://media.licdn.com/dms/image/v2/C4D03AQGJABVNmWo99w/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1568143403138?e=1743638400&v=beta&t=rpk18KmgEk2aysQDFc7O9XNlekC8QlCbsIhyyWkpAk8"
											alt="Akshay Ragoobeer"
											className="pp-class"
										></img>
									</picture>
								</div>
								<div className="profile-details">
									<div className="profile-name">Akshay</div>
									<div className="profile-position">
										Lead Developer/Engineer
									</div>
									<div className="profile-description">
										Akshay builds our platform, focusing on performance and user
										experience.
									</div>
									<div className="profile-socials">
										<a
											href="https://www.linkedin.com/in/akshayragoobeer/"
											target="_blank"
											rel="noreferrer"
										>
											<i className="linkedin-ico">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M4.5 3.24268C3.67157 3.24268 3 3.91425 3 4.74268V19.7427C3 20.5711 3.67157 21.2427 4.5 21.2427H19.5C20.3284 21.2427 21 20.5711 21 19.7427V4.74268C21 3.91425 20.3284 3.24268 19.5 3.24268H4.5ZM8.52076 7.2454C8.52639 8.20165 7.81061 8.79087 6.96123 8.78665C6.16107 8.78243 5.46357 8.1454 5.46779 7.24681C5.47201 6.40165 6.13998 5.72243 7.00764 5.74212C7.88795 5.76181 8.52639 6.40728 8.52076 7.2454ZM12.2797 10.0044H9.75971H9.7583V18.5643H12.4217V18.3646C12.4217 17.9847 12.4214 17.6047 12.4211 17.2246C12.4203 16.2108 12.4194 15.1959 12.4246 14.1824C12.426 13.9363 12.4372 13.6804 12.5005 13.4455C12.7381 12.568 13.5271 12.0013 14.4074 12.1406C14.9727 12.2291 15.3467 12.5568 15.5042 13.0898C15.6013 13.423 15.6449 13.7816 15.6491 14.129C15.6605 15.1766 15.6589 16.2242 15.6573 17.2719C15.6567 17.6417 15.6561 18.0117 15.6561 18.3815V18.5629H18.328V18.3576C18.328 17.9056 18.3278 17.4537 18.3275 17.0018C18.327 15.8723 18.3264 14.7428 18.3294 13.6129C18.3308 13.1024 18.276 12.599 18.1508 12.1054C17.9638 11.3713 17.5771 10.7638 16.9485 10.3251C16.5027 10.0129 16.0133 9.81178 15.4663 9.78928C15.404 9.78669 15.3412 9.7833 15.2781 9.77989C14.9984 9.76477 14.7141 9.74941 14.4467 9.80334C13.6817 9.95662 13.0096 10.3068 12.5019 10.9241C12.4429 10.9949 12.3852 11.0668 12.2991 11.1741L12.2797 11.1984V10.0044ZM5.68164 18.5671H8.33242V10.01H5.68164V18.5671Z"
														fill="black"
													/>
												</svg>
											</i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
*/}
				{/*contact us section */}
				<div className="contact-us-section about-page-contact-us" id="contact">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-6 col-sm-12">
								<div className="map-shot">
									<a
										href="https://maps.app.goo.gl/GnJTsp2smxYBtQdt5"
										target="_blank"
										rel="noreferrer"
									>
										<picture>
											<img
												src={mapImage}
												alt="map"
												style={{ maxHeight: "480px", width: "auto" }}
											/>
										</picture>
									</a>
								</div>
								{/**
                 * Google map use once api key sorted
                 * Remove the pic above and keep the below	
                 * ** then theres the useeffect code in the top of this file and last the import above that by imports		
                 * <div
										id="map"
										style={{ height: "480px", width: "100%" }}
									></div>
                 */}
							</div>
							<div className="col-lg-8 col-md-6 col-sm-12">
								<div className="container-fluid position-contact-form">
									<div className="contact-us-content">
										<div className="contact-us-right">
											<h1 className="contact-title">Contact Us</h1>
											<form className="contact-form" onSubmit={submitContact}>
												<div className="form-row">
													<div className="form-group">
														<i className="fa fa-user icon"></i>
														<input
															type="text"
															id="name"
															name="name"
															placeholder="First Name"
															className="input-icon"
															onChange={handleInputChange}
															required
														/>
													</div>
												</div>
												<div className="form-row">
													<div className="form-group">
														<i className="fa fa-envelope icon"></i>
														<input
															type="email"
															id="email"
															name="email"
															placeholder="Email"
															className="input-icon"
															onChange={handleInputChange}
															required
														/>
													</div>
												</div>
												<div className="form-row">
													<div className="form-group">
														<i
															className="fa fa-comments icon"
															style={{ marginTop: "-78px" }}
														></i>
														<textarea
															id="text"
															name="text"
															placeholder="Leave a Message"
															className="input-icon"
															onChange={handleInputChange}
															required
														></textarea>
													</div>
												</div>
												<div className="contact-us-buttons">
													<button type="submit" className="submit-button">
														Send
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="clearfix section-breaks"></div>
			</section>
			{/* divider to remove too */}
		</div>
	);
};

export default AboutUs;
