"use client";

import {
	Search,
	Globe,
	Shield,
	Database,
	Chrome,
	FileSearch,
} from "lucide-react";
import "./widgets.css";

const features = [
	{
		id: "domain-variations",
		title: "Generating Typo-Squatted Domain Variations",
		description:
			"We have developed our own advanced typo-squatting generation engine that surpasses all known tools, such as DNSTwister and URLCrazy. Our engine generates a comprehensive set of possible domain variations as attackers would and monitors them daily for new registrations.",
		Icon: Globe,
	},
	{
		id: "domain-tracking",
		title: "Tracking New Domain Registrations",
		description:
			"We analyze daily feeds of newly registered domains across all domain types, including country-code top-level domains (ccTLDs) and all new generic top-level domains (gTLDs). We use pattern-matching techniques like similarity scoring (e.g., detecting visually or phonetically similar names) to identify potential impersonators.",
		Icon: Search,
	},
	{
		id: "ssl-monitoring",
		title: "Monitoring SSL Transparency Logs",
		description:
			"Many attackers obtain SSL certificates for fraudulent sites. By scanning SSL logs, we detect when your brand name appears in certificates, including subdomains.",
		Icon: Shield,
	},
	{
		id: "threat-feeds",
		title: "Scanning Threat Intelligence Feeds",
		description:
			"We aggregate data from sources like PhishTank, URLhaus, and Google Safe Browsing to check if your domain appears in known phishing or malware databases. Since these sources publish entire URLs, including directories, we can detect cases where a phishing URL contains your brand name within a subdirectory, even if the domain itself is unrelated. This allows us to identify impersonation attempts that would otherwise go unnoticed.",
		Icon: Database,
	},
	{
		id: "google-scanning",
		title: "Scraping Google for Fraudulent Mentions",
		description:
			"We continuously scan Google search results and ads to detect unauthorized use of your brand name, including fake advertisements.",
		Icon: Chrome,
	},
	{
		id: "content-analysis",
		title: "Analyzing Content on Newly Registered Domains",
		description:
			"Cybercriminals frequently use unrelated domain names for phishing. We scan 200,000-300,000 newly registered domains daily and create a heatmap of commonly used keywords across all reserved domains on the internet. This allows us to identify emerging threats, detect brand mentions, and analyze patterns in scam-related content.",
		Icon: FileSearch,
	},
];

function FeatureTabs() {
	return (
		<div className="features-container feature-tabs-css pt-0 mt-0">
			<div className="features-grid">
				{features.map((feature) => (
					<div key={feature.id} className="feature-card">
						<div className="feature-icon">
							<feature.Icon size={24} />
						</div>
						<h3 class="feature-title-sub-main-title">{feature.title}</h3>
						<p>{feature.description}</p>
					</div>
				))}
			</div>
		</div>
	);
}

export default FeatureTabs;
