import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Divider,
    Link,
    Card,
    CardMedia,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    IconButton,
    DialogContent,
    Switch,
    LinearProgress,
} from '@mui/material';
import { format } from 'date-fns';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CircleIcon from '@mui/icons-material/Circle';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getFormattedDateTime } from '../../utiles/general';
import StatusBadge from '../common/statusBadge';
import { useNavigate } from 'react-router-dom';
import { sendTakeDown, updateDomainTakedown, updateDomainTakedownBlackLists, updateDomainTakedownProviders } from '../../api';
import { enqueueSnackbar } from 'notistack';


const initialBlacklistData = [
    { key: "google", provider: 'Google Safe Browsing', status: 'In Progress', enabled: false },
    { key: "microsoft", provider: 'Microsoft Security Intelligence', status: 'In Progress', enabled: false },
    { key: "spamhaus", provider: 'Spamhaus', status: 'In Progress', enabled: false },
    { key: "openphish", provider: 'OpenPhish', status: 'In Progress', enabled: false },
    { key: "netcraft", provider: 'Netcraft', status: 'In Progress', enabled: false },
];

// Define the initial provider configuration (can be defined outside or inside the component)
const initialProviderData = [
    { key: "hosting", type: "Hosting Provider", status: "Not Requested", enabled: false },
    { key: "registrar", type: "Domain Registrar", status: "Not Requested", enabled: false },
];

const timelineEvents = [
    {
        date: '2024-01-23T14:30:00',
        event: 'Takedown request initiated',
        type: 'initial'
    },
    {
        date: '2024-01-24T11:30:00',
        event: 'Spamhaus blacklist removed',
        type: 'progress'
    },
    {
        date: '2024-01-24T13:45:00',
        event: 'OpenPhish blacklist removed',
        type: 'progress'
    },
    {
        date: '2024-01-24T14:20:00',
        event: 'Google Safe Browsing blacklist removed',
        type: 'progress'
    },
    {
        date: '2024-01-24T16:30:00',
        event: 'Microsoft Security Intelligence blacklist removed',
        type: 'progress'
    },
    {
        date: '2024-01-25T10:15:00',
        event: 'Hosting provider took down the website',
        type: 'progress'
    },
    {
        date: '2024-01-25T11:30:00',
        event: 'Domain suspended by registrar',
        type: 'completion'
    }
];


const TakedownExpandedContent = ({ row, onAdditionalInfoChange, onTakedownChange, isSendingTakedown }) => {

    const navigate = useNavigate();

    const [providerData, setProviderData] = useState();
    const [blacklistData, setBlacklistData] = useState();


    const [selectedImage, setSelectedImage] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState(row.additional_notes || '');
    const [isEditing, setIsEditing] = useState(false);
    const [loadingAuthority, setLoadingAuthority] = useState(false);
    const [loadingBlacklists, setLoadingBlacklists] = useState(false);


    const additionalInfoRef = useRef(row.additional_notes);

    const handleImageExpand = (image) => {
        setSelectedImage(image);
    };

    const handleCloseImageDialog = () => {
        setSelectedImage(null);
    };

    const handleRescan = () => {

    };

    const handleViewScan = () => {

    };

    const handleSendTakedown = () => {
        
    };

    const handleAdditionalInfoChange = (e) => {
        const newValue = e.target.value;
        setIsEditing(additionalInfoRef.current !== newValue);
        setAdditionalInfo(newValue)
        onAdditionalInfoChange(newValue)
    };

    const handleSaveAdditionalInfo = () => {
        if (additionalInfo) {
            updateDomainTakedown(row.uid, additionalInfo).then((response) => {
                enqueueSnackbar("Additional notes have been saved", { variant: "success" })
                setAdditionalInfo(response.data.additional_notes)
                additionalInfoRef.current = response.data.additional_notes;
                setIsEditing(false)
            }).catch(() => {
                enqueueSnackbar("Additional notes could not be saved", { variant: "error" })
            })
        }
    };



    // Helper function to update provider/blacklist data based on the current requests
    const getUpdatedProviders = useCallback((requests) =>
        initialProviderData.map((provider) => {
            const matchingRequest = requests.find(
                (request) => request.authority_type === provider.type
            );
            return matchingRequest
                ? { ...provider, enabled: true, status: matchingRequest.status }
                : provider;
        }), []);

    const getBlackLists = (requests) =>
        initialBlacklistData.map((provider) =>
            requests.includes(provider.key)
                ? { ...provider, enabled: true, status: "Requested" }
                : provider
        );

    useEffect(() => {
        if (row.authority_takedown_requests) {
            const updatedProviders = getUpdatedProviders(row.authority_takedown_requests);
            setProviderData(updatedProviders);
            console.log(updatedProviders)
        }
    }, [row.authority_takedown_requests, getUpdatedProviders]);

    useEffect(() => {
        if (row.blacklists) {
            const updatedBlacklists = getBlackLists(row.blacklists);
            console.log(row.blacklists)
            console.log(updatedBlacklists)
            setBlacklistData(updatedBlacklists);
        }
    }, [row.blacklists]);


    const updateTakedownAuthority = (key, type, enabled) => {
        // Determine data based on the key
        const data = key === "hosting" ? { hosting: enabled } : { registrar: enabled };
        setLoadingAuthority(true)
        updateDomainTakedownProviders(row.uid, data)
            .then((response) => {
                enqueueSnackbar(`${type} enabled successfully`, { variant: "success" });
                const data = response.data;
                const updatedProviders = getUpdatedProviders(data.authority_takedown_requests);
                setProviderData(updatedProviders);
                onTakedownChange(data)
                setLoadingAuthority(false)
            })
            .catch(() => {
                enqueueSnackbar(`Could not enable ${type}`, { variant: "error" });
                setLoadingAuthority(false)
            });
    };

    const handleUpdateTakedownBlacklists = (key, type, enabled) => {
        let updatedBlacklists;
        setLoadingBlacklists(true)

        if (enabled) {
            // Add the key if it's not already present
            updatedBlacklists = row.blacklists.includes(key)
                ? row.blacklists
                : [...row.blacklists, key];
        } else {
            // Remove the key if it exists
            updatedBlacklists = row.blacklists.filter((item) => item !== key);
        }

        const data = {
            blacklists: updatedBlacklists.filter(item => item.trim() !== '')
        };

        updateDomainTakedownBlackLists(row.uid, data).then((response) => {
            enqueueSnackbar(`${type} enabled successfully`, { variant: "success" });
            const data = response.data;
            onTakedownChange(data)
            setLoadingBlacklists(false)
        }).catch(() => {
            enqueueSnackbar(`Could not enable ${type}`, { variant: "error" });
            setLoadingBlacklists(false)
        });
    };

    return (
        <Box sx={{ p: 2, pt: 1 }}>
            <Grid container spacing={3}>
                {/* Left Column Content */}
                <Grid item xs={12} md={6}>
                    {/* Basic Info */}
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            {row.maliciousDomain}
                        </Typography>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="body2" color="text.secondary">
                                Created At: {getFormattedDateTime(row.created_at)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Original Website: {row.original_domain}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Original IP: {row.original_ip}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Malicious IP: {row.malicious_ip}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Registrar Abuse Email: {row.registrarAbuseEmail}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Hosting Abuse Email: {row.hostingAbuseEmail}
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography variant="body2" color="text.secondary">
                                    <Link href={row.power_of_attorney?.signed_power_file_link} underline="hover">
                                        Download Signed Power of Attorney
                                    </Link>
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => { navigate(`/dashboard/domain-analysis/${row?.analysis_id}`, { state: { filteredDomain: row?.malicious_domain } }); }}
                                    startIcon={<ExitToAppIcon />}
                                    sx={{
                                        alignSelf: 'flex-start',
                                        textTransform: 'none',
                                        fontSize: '0.875rem',
                                    }}
                                >
                                    View Scan
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    {/* Website Cards */}
                    <Grid container spacing={2}>
                        {/* Original Website */}
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography variant="h6" sx={{ mb: 1 }}>
                                    Original Website
                                </Typography>
                                <Card sx={{
                                    position: 'relative',
                                    height: 250,
                                }}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            height: '100%',
                                            overflow: 'auto',
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            image={row.original_screenshot_link ? row.original_screenshot_link : "https://via.placeholder.com/600x300"}
                                            alt="Screenshot evidence 1"
                                            sx={{
                                                height: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </Box>
                                    <IconButton
                                        onClick={() => handleImageExpand(row.original_screenshot_link ? row.original_screenshot_link : "https://via.placeholder.com/600x300")}
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                            },
                                        }}
                                    >
                                        <ZoomOutMapIcon />
                                    </IconButton>
                                </Card>
                            </Box>
                        </Grid>

                        {/* Malicious Website */}
                        <Grid item xs={12} md={6} mb={3}>
                            <Box>
                                <Typography variant="h6" sx={{ mb: 1 }}>
                                    Malicious Website
                                </Typography>
                                <Card sx={{
                                    position: 'relative',
                                    height: 250,
                                }}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            height: '100%',
                                            overflow: 'auto',
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            image={row.malicious_screenshot_link ? row.malicious_screenshot_link : "https://via.placeholder.com/600x300"}
                                            alt="Screenshot evidence 2"
                                            sx={{
                                                height: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </Box>
                                    <IconButton
                                        onClick={() => handleImageExpand(row.malicious_screenshot_link ? row.malicious_screenshot_link : "https://via.placeholder.com/600x300")}
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                            },
                                        }}
                                    >
                                        <ZoomOutMapIcon />
                                    </IconButton>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Timeline */}
                    <Box>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Timeline
                        </Typography>
                        <Paper
                            elevation={0}
                            sx={{
                                height: 350,
                                overflowY: 'auto',
                                border: '1px solid rgba(224, 224, 224, 1)',
                                borderRadius: 1,
                                p: 2,
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                        >
                            {row.logs.map((event, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        position: 'relative',
                                        pl: 3,
                                        mb: index === row.logs.length - 1 ? 0 : 3,
                                        '&:not(:last-child)::after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '20px',
                                            left: '7px',
                                            width: '2px',
                                            height: 'calc(100% + 24px)',
                                            backgroundColor: 'primary.main',
                                        }
                                    }}
                                >
                                    <CircleIcon
                                        sx={{
                                            color: event.is_last ? '#66bb6a' : 'primary.main',
                                            fontSize: 16,
                                            position: 'absolute',
                                            left: 0,
                                            top: '4px'
                                        }}
                                    />
                                    <Box>
                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                            {format(new Date(event.created_at), 'MMM d, h:mm a')}
                                        </Typography>
                                        <Typography variant="body2">
                                            {event.action}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Paper>
                    </Box>
                </Grid>

                {/* Right Column Content */}
                <Grid item xs={12} md={6}>
                    {/* Registrar & Hosting Table */}
                    <Box sx={{ mb: 6 }}>
                        <Typography variant="h6" gutterBottom>
                            Registrar & Hosting
                        </Typography>
                        {loadingAuthority &&
                            <LinearProgress />
                        }
                        <TableContainer component={Paper} sx={{ maxHeight: 250, overflow: 'auto' }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 600, backgroundColor: 'white' }}>Provider</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 600, backgroundColor: 'white' }}>Status</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 600, backgroundColor: 'white' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {providerData?.map((item) => (
                                        <TableRow key={item.key}>
                                            <TableCell>{item.type}</TableCell>
                                            <TableCell align="right">
                                                {item.status !== "Not Requested"
                                                    ? <StatusBadge status={item.status} />
                                                    : item.status
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    <Switch
                                                        size='small'
                                                        disabled={isSendingTakedown || row.status.toLowerCase() !== 'draft'}
                                                        checked={item.enabled}
                                                        onChange={(e) => updateTakedownAuthority(item.key, item.type, !item.enabled)}
                                                    />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    {/* Blacklists Table */}
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Blacklists
                        </Typography>
                        {loadingBlacklists &&
                            <LinearProgress />
                        }
                        <TableContainer
                            component={Paper}
                            sx={{
                                height: 'auto',
                                border: '1px solid rgba(224, 224, 224, 1)',
                                borderRadius: 1,
                            }}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                fontWeight: 600,
                                                backgroundColor: 'white',
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                            }}
                                        >
                                            Provider
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontWeight: 600,
                                                backgroundColor: 'white',
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                            }}
                                        >
                                            Status
                                        </TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 600, backgroundColor: 'white' }}>Actions</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {blacklistData?.map((item) => (
                                        <TableRow
                                            key={item.provider}
                                            sx={{
                                                '&:last-child td': { border: 0 },
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                },
                                            }}
                                        >
                                            <TableCell>{item.provider}</TableCell>
                                            <TableCell align="right">
                                                <StatusBadge status={item.status} />
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    <Switch
                                                        size='small'
                                                        disabled={isSendingTakedown || row.status.toLowerCase() !== 'draft'}
                                                        checked={item.enabled}
                                                        onChange={(e) => handleUpdateTakedownBlacklists(item.key, item.type, !item.enabled)}
                                                    />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    {/* Additional Information */}
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            More Info
                        </Typography>
                        <Paper
                            elevation={0}
                            sx={{
                                border: '1px solid rgba(224, 224, 224, 1)',
                                borderRadius: 1,
                                p: 2,
                            }}
                        >
                            {row.status.toLowerCase() === 'draft' ? (
                                <Box>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={12}
                                        value={additionalInfo}
                                        onChange={(e) => handleAdditionalInfoChange(e)}
                                        placeholder="Add any additional information to send to Registrar, Hosting Provider or Blacklists..."
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            disabled={!isEditing}
                                            startIcon={<SaveIcon />}
                                            onClick={(e) => handleSaveAdditionalInfo(e)}
                                            sx={{
                                                textTransform: 'none',
                                                fontSize: '0.875rem',
                                            }}
                                        >
                                            {additionalInfo ? "Update" : "Save"}
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        minHeight: '175px',
                                        whiteSpace: 'pre-wrap',
                                        color: additionalInfo ? 'text.primary' : 'text.secondary',
                                        fontStyle: additionalInfo ? 'normal' : 'italic',
                                    }}
                                >
                                    {additionalInfo || 'No additional information was provided to be sent'}
                                </Typography>
                            )}
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

            {/* Image Preview Dialog */}
            <Dialog
                open={!!selectedImage}
                onClose={handleCloseImageDialog}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent sx={{ p: 0 }}>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Expanded screenshot"
                            style={{
                                width: '100%',
                                height: 'auto',
                                display: 'block'
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default TakedownExpandedContent; 