import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { forgotpassword } from "../actions/accountActions";
import {
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Link,
	TextField,
	Typography,
} from "@mui/material";
import { CheckCircleOutline, Email } from "@mui/icons-material";

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [forgotSent, setForgotSent] = useState(false);

	const validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const handleEmailChange = (e) => {
		const newEmail = e.target.value;
		setEmail(newEmail);
		if (!newEmail) {
			setEmailError("Email is required");
		} else if (!validateEmail(newEmail)) {
			setEmailError("Invalid email format");
		} else {
			setEmailError("");
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!email) setEmailError("Email is required");

		if (email && !emailError) {
			try {
				await dispatch(forgotpassword(email));
				setForgotSent(true);
				enqueueSnackbar("Success reset password", {
					variant: "success",
				});
			} catch (error) {
				const message = "Reset password Failed";
				enqueueSnackbar(message, {
					variant: "error",
				});
			}
		}
	};

	return (
		<Container
			disableGutters
			maxWidth={false}
			sx={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundImage:
					"url(/assets/images/account/SG_login_man_at_desk.webp)",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundSize: "cover",
			}}
		>
			{!forgotSent ? (
				<Card sx={{ maxWidth: 600, width: "100%", boxShadow: 3 }}>
					<CardContent sx={{ p: 4 }}>
						<Typography
							variant="h4"
							component="h1"
							sx={{
								fontWeight: "bold",
								textAlign: "center",
								mb: 3,
							}}
						>
							Forgot Password?
						</Typography>

						<Typography
							variant="body1"
							sx={{
								color: "text.secondary",
								textAlign: "center",
								mb: 3,
							}}
						>
							Enter the email address linked to your account, and we'll send you
							a password reset link.
						</Typography>

						<Box
							component="form"
							onSubmit={handleSubmit}
							sx={{ width: "100%" }}
						>
							<TextField
								fullWidth
								placeholder="Email"
								type="email"
								required
								value={email}
								onChange={(e) => handleEmailChange(e)}
								InputProps={{
									endAdornment: <Email sx={{ color: "action.active" }} />,
								}}
								sx={{
									mb: 3,
									"& .MuiOutlinedInput-root": {
										borderRadius: "4px",
									},
								}}
							/>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									borderRadius: "4px",
									padding: "12px",
									textTransform: "none",
									fontSize: "1.1rem",
									backgroundColor: "#2196f3",
									"&:hover": {
										backgroundColor: "#1976d2",
									},
								}}
							>
								Send Reset Link
							</Button>
						</Box>

						<Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
							Having trouble?{" "}
							<Link href="#" underline="hover" sx={{ color: "primary.main" }}>
								Contact us
							</Link>{" "}
							for assistance.
						</Typography>
					</CardContent>
				</Card>
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						backgroundColor: "white",
						padding: 4,
						borderRadius: "4px",
						boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
						width: "600px",
					}}
				>
					<CheckCircleOutline
						color="primary"
						sx={{ width: "60px", height: "60px" }}
					/>
					<Typography
						align="center"
						variant="h3"
						component="h1"
						sx={{ fontWeight: "bold", mb: 1 }}
					>
						Password Reset Requested
					</Typography>
					<Typography
						align="center"
						variant="h5"
						sx={{ mb: 4, color: "text.secondary" }}
					>
						If the email address you provided is registered with us, you will
						receive a password reset link shortly.
					</Typography>
				</Box>
			)}
		</Container>
	);
};

export default ForgotPassword;
