import { Grid, Box, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Stack, TablePagination, LinearProgress } from '@mui/material';
import AnalyticsCard from '../monitor-analytics';
import ScreenshotCard from '../monitor-screenshot';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import GavelIcon from '@mui/icons-material/Gavel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCallback, useEffect, useRef, useState } from 'react';
import MonitorSettingsModal from '../monitor-settings-modal';
import { getFurtherDomainAnalysis, getSingleDomainAnalysis, getSingleMonitor, getSingleMonitorAlerts } from '../../api';
import { enqueueSnackbar } from 'notistack';
import MonitorModal from '../new-monitor-modal';
import { useNavigate } from 'react-router-dom';
import TakedownModal from '../new-takedown-modal';
import { getFormattedDate, getFormattedDateTime } from '../../utiles/general';

function ExpandedContent({ monitor, takedown }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isTakedownOpen, setIsTakedownOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [analysis, setAnalysis] = useState(null);

    const [sections, setSections] = useState(null);
    const didLoadRef = useRef(false);
    const [alerts, setAlerts] = useState(null);

    const navigate = useNavigate()

    const loadRowDetails = useCallback(() => {
        if (!didLoadRef.current) {
            didLoadRef.current = true;

            setIsLoading(true)

            getSingleMonitor(monitor.uid)
                .then((response) => {

                    if (monitor.analysis_id && monitor.uid) {
                        Promise.all([
                            getSingleMonitorAlerts(monitor.uid),
                            getSingleDomainAnalysis(monitor.analysis_id),
                            getFurtherDomainAnalysis(monitor.analysis_id)
                        ])
                            .then(([alertsResponse, initialScanResponse, furtherScanResponse]) => {
                                setAlerts(alertsResponse.data?.results);
                                setAnalysis(initialScanResponse?.data)

                                const initialScanData = initialScanResponse?.data;
                                const filteredInitialData = initialScanData.dns_lookups_formatted?.find(x => x.domain === monitor.domain);

                                const furtherScanData = furtherScanResponse?.data;
                                const filteredFurtherData = furtherScanData.further_results_formatted?.find(x => x.domain === monitor.domain);

                                // Combine the results.
                                const scanInfo = {
                                    dns: {
                                        domain: filteredInitialData?.domain ?? "N/A",
                                        aRecord: filteredInitialData?.a_record ?? "N/A",
                                        mxRecord: filteredInitialData?.mx_record ?? "N/A",
                                        nsRecord: filteredInitialData?.ns_record ?? "N/A",
                                        httpsOpen: filteredFurtherData?.http_open ? "True" : "False",
                                        httpOpen: filteredFurtherData?.https_open ? "True" : "False",
                                    },
                                    content: {
                                        screenshot: filteredInitialData?.screenshot,
                                        contentSpoofed: filteredInitialData?.content_spoofed ?? "N/A"
                                    },
                                    spoofing: {
                                        urlScore: filteredFurtherData?.urlscore ?? "N/A",
                                        isSpoofing: filteredFurtherData?.spoofing ?? "N/A",
                                        phishingStatus:
                                            Object.keys(filteredFurtherData?.phishing || {}).length === 0
                                                ? "N/A"
                                                : filteredFurtherData?.phishing
                                    },
                                    whois: {
                                        creationDate: filteredInitialData?.whois?.creation_date ?? "N/A",
                                        expirationDate: filteredInitialData?.whois?.expiration_date ?? "N/A",
                                        abuseEmail: filteredInitialData?.whois?.emails ?? "N/A",
                                        registrar: filteredInitialData?.whois?.registrar ?? "N/A",
                                        address: filteredInitialData?.whois?.address ?? "N/A"
                                    }
                                };

                                setSections(scanInfo);
                                setIsLoading(false);
                            })
                            .catch(() => {
                                enqueueSnackbar("Failed to load alerted changes", { variant: "error" });
                                setIsLoading(false);
                            });
                    } else {
                        // If there's no analysis_id, just get the alerts.
                        getSingleMonitorAlerts(monitor.uid)
                            .then((alertsResponse) => {
                                setAlerts(alertsResponse.data);
                                setIsLoading(false);
                            })
                            .catch(() => {
                                enqueueSnackbar("Failed to load alerted changes", { variant: "error" });
                                setIsLoading(false);
                            });
                    }
                })
                .catch((err) => {
                    console.error("Failed to load monitor details", err);
                });
        }
    }, [monitor.uid, monitor.analysis_id, monitor.domain]);

    useEffect(() => {
        if (monitor.uid) {
            loadRowDetails();
        }
    }, [monitor.uid, loadRowDetails])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const monitorsEnabled = (showLoading) => {
        setIsLoading(showLoading);
        //setMonitorModalOpen(false)
    }

    return (
        <Box
            p={0}
            pb={3}
            sx={{
                backgroundColor: '#f5f5f5',
                margin: 0
            }}
        >
            {isLoading &&
                <LinearProgress />
            }
            <Box px={3} sx={{ mt: '12px' }}>
                {/* {alerts &&
                    <AnalyticsCard monitor={monitor} alerts={alerts} />
                } */}

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        mt: '20px',
                        mb: 1,
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        variant="contained"
                        startIcon={<SettingsIcon />}
                        onClick={() => setIsSettingsOpen(true)}
                        sx={{ bgcolor: 'primary.main' }}
                    >
                        Monitor Settings
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        onClick={() => { navigate(`/dashboard/domain-analysis/${monitor?.analysis_id}`, { state: { filteredDomain: monitor?.domain } }); }}
                        sx={{ bgcolor: 'primary.main' }}
                    >
                        View Scan
                    </Button>
                    {(takedown?.status === 'Not Initiated' || !takedown) ? (
                        <Button
                            variant="contained"
                            startIcon={<GavelIcon />}
                            onClick={() => { setIsTakedownOpen(true) }}
                            sx={{ bgcolor: 'error.main', '&:hover': { bgcolor: 'error.dark' } }}
                        >
                            Request Takedown
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            startIcon={<VisibilityIcon />}
                            onClick={() => { navigate(`/dashboard/takedowns`); }}
                            sx={{ bgcolor: 'primary.main' }}
                        >
                            View Takedown
                        </Button>
                    )}
                </Stack>
            </Box>

            {/* <MonitorSettingsModal
                open={isSettingsOpen}
                onClose={() => setIsSettingsOpen(false)}
                monitor={monitor}
            /> */}

            <MonitorModal
                open={isSettingsOpen}
                onClose={() => setIsSettingsOpen(false)}
                selectedDomains={[monitor.domain]}
                onMonitorsEnabled={monitorsEnabled}
                analysis={analysis}
                monitor={monitor}
            />

            <TakedownModal
                open={isTakedownOpen}
                onClose={() => setIsTakedownOpen(false)}
                domain={monitor.domain}
                originalDomain={takedown?.original_domain}
                scanData={analysis}
            />

            {sections &&
                <Grid
                    container
                    spacing={3}
                    sx={{
                        mt: '12px',
                        mx: 0,
                        px: {
                            xs: 2,
                            md: 3
                        },
                        width: '100%',
                        '& .MuiGrid-item': {
                            pt: '12px !important',
                            pl: '0px !important'
                        }
                    }}
                >
                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pr: {
                            xs: 0,
                            md: 3
                        }
                    }}>
                        <Paper
                            elevation={1}
                            sx={{
                                p: 2,
                                backgroundColor: '#ffffff'
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Initial Scan Results
                            </Typography>

                            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                                DNS Info
                            </Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {Object.entries(sections?.dns).map(([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row" sx={{ border: 'none' }}>
                                                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                                                </TableCell>
                                                <TableCell align="right" sx={{ border: 'none' }}>{value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: 'bold' }}>
                                WHOIS Info
                            </Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {Object.entries(sections?.whois).map(([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row" sx={{ border: 'none' }}>
                                                    {key === 'creationDate' ? 'Domain Creation Date' :
                                                        key === 'expirationDate' ? 'Domain Expiration Date' :
                                                            key === 'abuseEmail' ? 'Abuse Email' :
                                                                key === 'registrar' ? 'Registrar' :
                                                                    key === 'address' ? 'Address' :
                                                                        key === 'registrant' ? 'Registrant' :
                                                                            key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                                                </TableCell>
                                                <TableCell align="right" sx={{ border: 'none' }}>{value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        {sections?.content?.screenshot &&
                            <ScreenshotCard
                                screenshotUrl={sections?.content?.screenshot}
                                sx={{ mt: 0.625 }}
                            />
                        }


                    </Grid>

                    <Grid className='grid' item xs={12} md={6} sx={{
                        mt: {
                            xs: 0.625,
                            md: 0
                        }
                    }}>
                        <Paper
                            elevation={1}
                            sx={{
                                p: 0,
                                backgroundColor: '#ffffff',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h6" sx={{ p: 2, pb: 1 }}>
                                Alerts
                            </Typography>

                            {alerts.length > 0 ?
                                <>
                                    <TableContainer
                                        sx={{
                                            flex: 1,
                                            overflow: 'auto'
                                        }}
                                    >
                                        <Table stickyHeader size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ pl: 2 }}>Message</TableCell>
                                                    <TableCell>Created At</TableCell>
                                                    {/* <TableCell>Status</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {alerts && alerts?.slice(page * 10, page * 10 + 10).map((alert, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ pl: 2 }}>{alert.message}</TableCell>
                                                        <TableCell>{getFormattedDateTime(alert.created_at)}</TableCell>
                                                        {/* <TableCell>{alert.status}</TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        count={alerts?.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={10}
                                        rowsPerPageOptions={[]}
                                        sx={{ px: 2 }}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                                : <Typography variant="subtitle2" sx={{ p: 2, pb: 1 }}>
                                    No alerts available for this monitor
                                </Typography>
                            }
                        </Paper>
                    </Grid>
                </Grid>

            }
        </Box>
    );
}

export default ExpandedContent; 